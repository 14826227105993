import React, { Component } from "react";
import "./Specs.css";

export default class Specs extends Component {
  render() {
    return (
      <div>
        {this.props.material && <div id="description"></div>}
        {!this.props.price ? (
          <h1 className={this.props.tech ? "specs-heading technical" : "specs-heading"}>
            {this.props.tech && "Technical "}Specifications:
          </h1>
        ) : (
          <h1 className={this.props.labels ? "specs-heading technical" : "specs-heading"}>
            {this.props.h ? this.props.h : "Prices:"}
          </h1>
        )}

        <table>
          <tr>
           {this.props.signs && <thead>
              <td></td>
              <td>1 unit</td>
              {!this.props.single && <td>5 units + </td>}
            </thead>}
            {this.props.labels && <thead>
              <td></td>
              <td>100 units</td>
              {!this.props.single && <td>1000 units</td>}
            </thead>}
            {this.props.list.map((item) => (
              <tr valign="top" key={item.name}>
                <td className="spec-name">
                  <b>{item.name}</b>
                </td>
                {item.middle && (
                  <td className={this.props.price ? "middle" : ""}>
                    {item.middle}
                  </td>
                )}
                <td className={this.props.price ? "price" : ""}>
                  {item.description}
                </td>
              </tr>
            ))}
          </tr>
        </table>
        <br />
      </div>
    );
  }
}
