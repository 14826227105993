import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ProductPresent from "../blocks/ProductPresent/ProductPresent";
import "./pages.css";
import { Divider } from "antd";
import { logEvent } from "firebase/analytics";
const banners_data = [
  {
    text: `Boost your brand visibility with our high-quality, weather-resistant vinyl banners. Designed for maximum impact and durability, these banners are perfect for outdoor advertising, events, and promotions. Customise your message with a variety of sizes and finishes, enjoy easy installation, and watch your sales soar with our premium vinyl banners. Their fade-resistant inks ensure a long-lasting, vibrant display that captures the attention of potential customers and drives business growth.`,
    title: "Vinyl Banners",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fbanners%2Fbanner.png?alt=media&token=9c30fdde-32c3-4486-b157-522c5fb86591",
    alt: "Outdoor vinyl banner beauty saloon",
    link: "contact",
    id:'vinyl',
    price: "18.10 / m²",
    reverse: false,
  },
  {
    text: `Showcase your message in style with our superior mesh banners. Ideal for windy conditions, these banners offer excellent airflow, reducing wind resistance and ensuring longevity. Stand out at outdoor events, construction sites, or sports arenas with our visually striking, durable mesh banners. The unique perforated design allows for high-quality images while maintaining a light and versatile structure. Increase sales and brand recognition with these eye-catching, dependable banners.`,
    title: "Mesh Banners",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fbanners%2Fmesh%20big.png?alt=media&token=113d7bc7-4dbc-4db1-acdb-5f37fed02c61",
    alt: "Wind resistant banner",
    id:'mesh',
    link: "contact",
    price: "17.80 / m²",
    reverse: true,
  },
  {
    text: `Comfy your marketing game with our eco-friendly textile banners. Made from sustainable materials, these banners offer a soft, sophisticated appearance with vibrant colors that demand attention. Perfect for indoor promotions, exhibitions, and retail spaces, our textile banners help you achieve increased sales with a premium look. Their lightweight, wrinkle-resistant fabric makes transportation and installation a breeze, while the exceptional print quality ensures a stunning visual impact for your brand.`,
    title: "Textile Banners",
    img: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Banere%2Fbaner-textil%2Fce-este-un-baner-din-textil.jpg?alt=media&token=c70389ed-0214-4f13-9027-2a973c8bc9bc",
    alt: "Indoor standing roller banner.",
    price: "31.20 / m²",
    id:'textile',
    link: "contact",
    reverse: false,
  },
  {
    text: `Illuminate your brand message with our captivating backlit banners. Designed to make your graphics shine day and night, these banners ensure maximum visibility for your promotions. Ideal for retail stores, trade shows, and events, our backlit banners are the ultimate solution for driving sales and attracting customers 24/7. Made with high-quality, translucent materials, they provide an even light distribution, creating an eye-catching glow that sets your message apart from the competition and makes a lasting impression on potential customers.`,
    title: "Backlit Banners",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fbacklit.png?alt=media&token=b4c67296-0d63-438f-b8cb-6f9ea5b426e7",
    alt: "illuminated banners",
    id:'backlit',
    price: "35.80 / m²",
    link: "contact",
    reverse: true,
  },
];

const promo_data = [
  {
    text: `Elevate your promotional strategy with our versatile and portable standing X banners. Perfect for trade shows, conferences, and in-store displays, these banners effectively showcase your brand message while occupying minimal floor space. Their lightweight design and simple assembly make transportation and setup effortless. With vibrant, high-quality printing, our standing X banners guarantee increased visibility, customer engagement, and sales growth for your business.`,
    title: "Standing X banners",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fbanners%2Fxbannerb.png?alt=media&token=5e7a6f4d-0f68-40d8-936e-025971771db1",
    alt: "Indoor standing roller banner.",
    price: "49.95 / piece",
    link: "contact",
    id: "x-banner",
    reverse: false,
  },
  {
    text: `Attract attention and drive sales with our dynamic, high-impact banner flags. Perfect for outdoor events, storefronts, and festivals, these flags provide a striking, unique way to showcase your brand. Designed to withstand various weather conditions, our banner flags maintain their vibrant colors and captivating movement, ensuring maximum visibility. Choose from a variety of shapes and sizes to create a customised advertising solution that generates increased foot traffic and sales for your business.`,
    title: "Banner Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fbanner_flag%20small.png?alt=media&token=9a1ff5b5-ab89-4188-8be3-ad964f92c749",
    alt: "Rectangular banner flags",
    price: "133.30 / piece",
    link: "contact",
    id:'flag',
    reverse: false,
  },
];
export default class Banners extends Component {
  render() {
      logEvent(this.props.anal, 'banners_visited');
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div style={{ maxWidth: "1360px", margin: "0px auto", padding:"0px 16px" }}>
          {banners_data.map((item) => (
            <div id={item.id} key={item.id}>
              <ProductPresent
                text={item.text}
                title={item.title}
                img={item.img}
                alt={item.alt}
                link={item.link}
                reverse={item.reverse}
                price={item.price}
              />
              <Divider  style={{marginTop:'0px'}}/>
            </div>
          ))}

          <h1 className="sub-h">Standing Banners</h1>
          {promo_data.map((item) => (
            <div id={item.id} key={item.id}>
              <ProductPresent
                text={item.text}
                title={item.title}
                img={item.img}
                alt={item.alt}
                link={item.link}
                reverse={item.reverse}
                price={item.price}
              />
              <Divider  style={{marginTop:'0px'}}/>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}
