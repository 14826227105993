import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import "./index.css";

// Import of pages here:
import App from "./App";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Technical from "./components/pages/Technical";
import PP from "./components/pages/PP";
import TC from "./components/pages/TC";
import Banners from "./components/pages/Banners";
import Signs from "./components/pages/Signs";
import Badges from "./components/pages/Badges";
import Stickers from "./components/pages/Stickers";
import Flags from "./components/pages/Flags";

import { data } from "./data/data";

const firebaseConfig = {
  apiKey: "AIzaSyDtzvfa0rJNucxVzPt94D9lm8M_4lrcxHI",
  authDomain: "total-promote.firebaseapp.com",
  projectId: "total-promote",
  storageBucket: "total-promote.appspot.com",
  messagingSenderId: "557411455583",
  appId: "1:557411455583:web:f04fe87c0f7108a16e81ea",
  measurementId: "G-GVJXC8KWR6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App categories={data} />} />
      <Route path="/contact" element={<Contact categories={data} db={db}/>} />
      <Route path="/signs" element={<Signs anal={analytics} categories={data} />} />
      <Route path="/badges" element={<Badges anal={analytics} categories={data} />} />
      <Route path="/flags" element={<Flags anal={analytics} categories={data} />} />
      <Route path="/about" element={<About categories={data} />} />
      <Route path="/banners" element={<Banners anal={analytics} categories={data} />} />
      <Route path="/stickers" element={<Stickers anal={analytics} categories={data} />} />
      <Route path="/privacy-policy" element={<PP categories={data} />} />
      <Route path="/terms" element={<TC categories={data} />} />
      <Route path="/technical" element={<Technical categories={data} />} />
    </Routes>
  </BrowserRouter>
);
