import React, { Component } from "react";
import "./ProductPresent.css";

export default class ProductPresent extends Component {
  state = {};

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }
  renderReverse = () => {
    return (
      <div className="pp-container">
        <div className="pp-img-container-r">
          <img className="pp-img" src={this.props.img} alt={this.props.alt} />
        </div>
        <div className="pp-content">
          <h1 className="pp-h">{this.props.title}</h1>
          <p className="pp-p">{this.props.text}</p>
          {this.props.price && (
            <p>
              <span className="pp-p">from: </span>{" "}
              <span className="pp-price">£{this.props.price}</span>
            </p>
          )}
          <a href={"/" + this.props.link}>
            <button
              className="hero-button pp-btn"
              style={{ marginLeft: "0px" }}
              href={"/" + this.props.link}
            >
              Find out more
            </button>
          </a>
        </div>
      </div>
    );
  };
  renderMobile = () => {
    return (
      <div className="pp-container">
        <h1 className="pp-h" style={{textAlign:'center', marginTop:'32px'}}>{this.props.title}</h1>
        <div className="pp-img-container-r">
          <img className="pp-img" src={this.props.img} alt={this.props.alt} />
        </div>
        <div className="pp-content">
          <p className="pp-p">{this.props.text}</p>
          {this.props.price && (
            <p>
              <span className="pp-p">from: </span>{" "}
              <span className="pp-price">£{this.props.price}</span>
            </p>
          )}
          <a href={"/" + this.props.link}>
            <button
              className="hero-button pp-btn"
              style={{ marginLeft: "0px" }}
              href={"/" + this.props.link}
            >
              Find out more
            </button>
          </a>
        </div>
      </div>
    );
  };

  render() {
    if (this.state.mobile) {
      return this.renderMobile();
    }
    if (this.props.reverse) {
      return this.renderReverse();
    } else {
      return (
        <div className="pp-container">
          <div className="pp-content">
            <h1 className="pp-h">{this.props.title}</h1>
            <p className="pp-p">{this.props.text}</p>
            {this.props.price && (
              <p>
                <span className="pp-p">from: </span>{" "}
                <span className="pp-price">£{this.props.price}</span>
              </p>
            )}
            <a href={"/" + this.props.link}>
              <button
                className="hero-button"
                style={{ marginLeft: "0px" }}
                href={"/" + this.props.link}
              >
                Find out more
              </button>
            </a>
          </div>
          <div className="pp-img-container">
            <img className="pp-img" src={this.props.img} alt={this.props.alt} />
          </div>
        </div>
      );
    }
  }
}
