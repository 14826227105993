import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ImageText from "./components/blocks/ImageText/ImageText";
import Top from "./components/blocks/Top/Top";
import Hero from "./components/main/Hero/Hero";
import "./components/Header.css";
import "./App.css";
import { Divider, notification } from "antd";

const openNotification = () => {
  const TP = localStorage.getItem("TP");
  if (TP) { return; }
  notification.open({
    message: "Cookies",
    description:
      "Navigating this website you agree with our cookies and privacy policy.",
    placement: "bottomRight",
    onClick: () => {
      console.log("Notification Clicked!");
    },
  });
};
const renderItems = (item, url) => {
  return (
    <div className="hb" key={item.name}>
      <a href={"/" + url}>
        <img alt={item.name} className="home-product-img" src={item.url} />
        <h2
          className="hb-title"
          style={{ textAlign: "center", fontSize: "18px" }}
        >
          {item.name}
        </h2>
      </a>
      <p className="hbp">{item.description}</p>
    </div>
  );
};

const banners_data = [
  {
    name: "Vinyl Banners",
    description: "Outdoor durable and resistant advertising banner.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fbanner_1.jpg?alt=media&token=104e2649-933f-44b2-99e1-979cb369a380",
  },
  {
    name: "Mesh Banners",
    description:
      "Outdoor perforated wind resistant banner. Great for large surfaces.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fmesh_1.jpg?alt=media&token=df34cabb-5764-4781-aa90-067e03881bd1",
  },
  {
    name: "Backlit Banners",
    description: "Backlit illuminated banner. Great visibility at nigh.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fbacklit_1.jpg?alt=media&token=337be7c1-5f7d-4f2c-bab2-7a17587866a3",
  },
  {
    name: "Standing X Banners",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fxbanner_1.jpg?alt=media&token=b70d945d-c8a3-475b-9705-db51762dfe3c",
    description:
      "Great standing banner for indoor advertisement. Similar to roller banners.",
  },
  {
    description: "Customised rectangular banner flags.",
    name: "Banner Flags",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fflags_1.jpg?alt=media&token=1deef5fc-d42e-4337-901e-9d9e1b6dbfc3",
  },
];
const signs_data = [
  {
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fsign_1.jpg?alt=media&token=aeb8d5a5-68c6-46c2-b5c5-c2a0164aa19a",
    description: "Transparent glass like panel. Glass company signs.",
    name: "Acrylic Signs",
  },
  {
    name: "Plastic Signs",
    description: "Resistant affordable plastic signs made out of PVC.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-pvc%2FCover.jpg?alt=media&token=2f69e491-a7d7-42a1-9e3d-b6363956f516",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-dibond%2Fmain-cover.jpg?alt=media&token=57f7660a-1bd7-414b-9ed6-27f80a53d84e",
    name: "Aluminium Signs",
    description: "Professional company aluminium signs printed or engraved.",
  },
  {
    description: "Printed or engraved wooden signs made out of plywood.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-placaj%2FCover.jpg?alt=media&token=9fbdf57f-dea5-4c04-b090-939c75b9799f",
    name: "Wooden Signs",
  },
  {
    description: "Made of Stainless Steel or Premium 2mm thick Aluminium.",
    name: "Premium Metal Signs",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fp_sign_1.jpg?alt=media&token=4ff2ce73-aac5-41c6-94bb-06101b27454b",
  },
];
const badges_data = [
  {
    description:
      "Professional affordable name badges. Printed or engraved in plastic.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-plastic%2Fcover%20ecuson%20din%20plastic.jpg?alt=media&token=b3a5a23c-9d31-44d9-8798-8b64c7e165ec",
    name: "Plastic name badges",
    id: "badges",
  },
  {
    id: "badges",
    description: "Premium acrylic name badges for a sleek, modern appearance.",
    name: "Acrylic name badges",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fbadge_1.jpg?alt=media&token=0cfa4d02-2070-4cd2-8a76-288c0651dd32",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-placaj%2Fcover%20ecusoane%20din%20lemn.jpg?alt=media&token=97c1b442-0a49-4de7-bce1-46ce4e647e05",
    name: "Wooden name badges",
    description: "Eco-friendly wooden name badges for a natural look.",
    id: "badges",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-aluminiu%2Faluminiu1.jpg?alt=media&token=ca230565-35ba-46b0-a1e5-a3029d0642fb",
    id: "badges",
    name: "Aluminium badges",
    description:
      "Durable aluminium name badges for a lightweight and professional design.",
  },
  {
    description:
      "High-quality stainless steel name badges for a long-lasting and stylish finish.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-inox%2Finox.jpg?alt=media&token=6882162b-76a0-417f-be06-fdaf68ff3b04",
    id: "badges",
    name: "Stainless steel badge",
  },
];
const stickers_data = [
  {
    name: "Label Stickers",
    description:
      "Versatile small stickers ideal for product labeling and various applications.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Flabels_1.jpg?alt=media&token=82bb3dc3-0257-4f81-b1f5-ccdab44cd0d7",
  },
  {
    name: "Perforated Sticker",
    url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Stickere%2Fsticker-perforat%2FUntitled-2.jpg?alt=media&token=43d85110-38b0-49ca-8a10-d9065258ccea",
    description:
      "One-way vision perforated window stickers, perfect for promotion and maintaining natural light inside.",
  },
  {
    name: "Car Stickers & Decals",
    description:
      "Customisable car stickers and decals to personalise and promote your brand on-the-go.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fcar.png?alt=media&token=469a0876-7bf6-4331-95fc-93fe0a143e61",
  },
  {
    name: "Window Stickers",
    description:
      "Attractive window stickers for branding and promotional purposes on storefronts and glass surfaces.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fwindow_1.jpg?alt=media&token=6b70e999-5d18-4c19-8ef0-c0f048dc53b8",
  },
  {
    name: "Wall Stickers",
    description:
      "Customisable wall stickers with adhesive letters, perfect for interior design and branding.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fwall_1.jpg?alt=media&token=d230881a-5ffa-4ae7-8207-75677b349b31",
  },
];
const flags_data = [
  {
    name: "Feather Flag",
    description:
      "Feather or Sail Flags are great for events and outdoor brand promotion.",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fsff.png?alt=media&token=6c9351f4-6fd4-4994-a10c-f0d78ee4efe9",
  },
  {
    name: "Teardrop Flag",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fstf.png?alt=media&token=624a4c7f-c73e-4a40-b216-59a32cc94a6f",
    description: "Stand out in the crowd with a rounded teardrop flag design",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflag-great-britain.jpg?alt=media&token=7fee7c74-a766-4b35-aef4-4826dd40e49c",
    description: "High quality national and custom flags. Any design or size.",
    name: "National Flag",
  },
  {
    description:
      "Premium national flags made of atlas. Perfect for governmental institutions.",
    name: "Premium Flags",
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fpflag_1.jpg?alt=media&token=b82c18a1-bcd3-45fa-b850-dd23603882f6",
  },
  {
    url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Ftable-flag.jpg?alt=media&token=a113081a-0234-4613-9988-cafb913c143d",
    description: "Small table flags of high quality. Stands available as well.",
    name: "Table Flags",
  },
];

class App extends Component {
  state = {
    categories: this.props.categories,
    covers: [],
    mCovers: [],
    dCovers: [],
  };
  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    openNotification();
    localStorage.setItem("TP", true)
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <Hero mobile={this.state.mobile} />
        <div className="tpc">
          <h2 className="category-title">Banners</h2>
          <div className="hp-container">
            {banners_data.map((item) => renderItems(item, "banners"))}
          </div>
          <h2 className="category-title">Signs</h2>
          <div className="hp-container">
            {signs_data.map((item) => renderItems(item, "signs"))}
          </div>
          <h2 className="category-title">Name Badges</h2>
          <div className="hp-container">
            {badges_data.map((item) => renderItems(item, "badges"))}
          </div>
          <h2 className="category-title">Stickers & Decals</h2>
          <div className="hp-container">
            {stickers_data.map((item) => renderItems(item, "stickers"))}
          </div>
          <h2 className="category-title">Flags</h2>
          <div className="hp-container">
            {flags_data.map((item) => renderItems(item, "flags"))}
          </div>
          <Divider />
          <ImageText
            title="Professional Design Services"
            text="We offer outstanding design solutions for a polished, striking aesthetic, including custom logos, visuals, and impactful layouts. Our talented designers will transform your vision into reality. 
            Additionally, we provide this service for FREE on larger orders, ensuring high-quality results while saving on your project. Collaborate with us and let our in-house experts elevate your brand."
            alt="free design service"
            img="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fdesign_1.jpg?alt=media&token=23a93fdf-6c6c-4a78-84c8-1f532a15fc1a"
          />
          <Top />
        </div>
        <Footer />
      </div>
    );
  }
}
export default App;
