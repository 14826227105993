import { Button } from "antd";
import { Carousel } from "antd";
import React, { Component } from "react";
import "./PresentCarousel.css";

export default class PresentCarousel extends Component {
  render() {
    return (
      <div className="pc-container">
        <div className="pc-content">
          <h1 className="pc-h1">{this.props.title}</h1>
          <h2 className="pc-h2">{this.props.subtitle}</h2>
          <h3 className="pc-h3">{this.props.text}</h3>
          {this.props.link && (
            <Button href={"/" + this.props.link} type="primary" size="large">
              Find out more
            </Button>
          )}
        </div>
        <div className="pc-carousel">
          <Carousel autoplay>
            {this.props.list.map((image, index) => (
              <div style={{display:'block'}}>
                <img
                  src={image}
                  alt={this.props.altList[index]}
                  className="pc-image"
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}
