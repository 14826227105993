import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ProductPresent from "../blocks/ProductPresent/ProductPresent";
import Specs from "../blocks/Specs/Specs";
import './pages.css'
import { Divider } from "antd";
import { logEvent } from "firebase/analytics";

const signs_data = [
  {
    text: "Discover our durable plastic PVC signs, perfect for any indoor or outdoor setting. Lightweight and cost-effective, these signs are resistant to water, UV rays, and weathering. Customise yours today and boost your brand visibility with this versatile material. Experience the benefits of plastic PVC signs, and watch your business grow.",
    title: "PVC Plastic Signs",
    img: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-pvc%2Fmain_5.jpg?alt=media&token=e4a460b0-7329-4d6b-b81a-997c8afebd93",
    alt: "PVC Plastic Signs",
    link: "contact",
    reverse: false,
    list: [
      {
        name: "20cm x 30cm",
        middle: "£7.05",
        description: "£4.99",
      },
      {
        name: "30cm x 40cm",
        middle: "£10.60",
        description: "£8.60",
      },
      {
        name: "40cm x 60cm",
        middle: "£17.80",
        description: "£15.80",
      },
    ],
  },
  {
    text: "Elevate your business with our sleek and modern acrylic signs. Highly customisable and offering a stunning glass-like appearance, these signs are perfect for showcasing your brand with sophistication. Durable and easy to clean, acrylic signs provide a professional and lasting impression. Get your personalised acrylic sign and stand out today.",
    title: "Acrylic Signs",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fglass.jpg?alt=media&token=03766580-e07e-436c-b79e-ccc45ec6cdbc",
    alt: "Acrylic Signs",
    link: "contact",
    reverse: false,
    list: [
      {
        name: "20cm x 30cm",
        middle: "£12.70",
        description: "£7.10",
      },
      {
        name: "30cm x 40cm",
        middle: "£18.40",
        description: "£12.90",
      },
      {
        name: "40cm x 60cm",
        middle: "£29.99",
        description: "£24.60",
      },
    ],
  },
  {
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsigns%2F76532.jpg?alt=media&token=23b7484c-5562-4a8e-8e44-de52c42247c8",
    title: "Aluminium Signs",
    text: "Upgrade your signage with our robust aluminium signs. Rust-resistant, weatherproof, and lightweight, these signs are designed to last for years. Choose from a variety of finishes and customisations to create a sign that reflects your brand's style. Enhance your business with the lasting appeal of aluminium signs.",
    alt: "Aluminium Signs",
    link: "contact",
    reverse: false,
    list: [
      {
        name: "20cm x 30cm",
        middle: "£11.20",
        description: "£5.60",
      },
      {
        name: "30cm x 40cm",
        middle: "£15.40",
        description: "£9.99",
      },
      {
        name: "40cm x 60cm",
        middle: "£23.90",
        description: "£19.10",
      },
    ],
  },

  {
    text: "Embrace the natural warmth and charm of our wooden signs. Perfect for adding a rustic or vintage touch to your business, these signs are crafted from high-quality wood for long-lasting appeal. Customise your design and let the timeless beauty of wooden signs attract attention and customers alike.",
    title: "Wooden Signs",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsigns%2Fwine-bottle-sealing-box.jpg?alt=media&token=0ecc0c3e-6d51-4374-beea-cbb56ac97878",
    alt: "Wooden Signs",
    link: "contact",
    reverse: false,
    list: [
      {
        name: "20cm x 30cm",
        middle: "£7.30",
        description: "£5.20",
      },
      {
        name: "30cm x 40cm",
        middle: "£11.10",
        description: "£9.10",
      },
      {
        name: "40cm x 60cm",
        middle: "£18.80",
        description: "£17.00",
      },
    ],
  },
  {
    text: "Invest in the unmatched strength and durability of our polycarbonate signs. Virtually unbreakable and resistant to extreme weather conditions, these signs are perfect for high-traffic areas. Showcase your brand with confidence, knowing your polycarbonate sign will withstand the test of time.",
    title: "Anti-vandal Polycarbonate Signs",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsigns%2Fphoto_2023-05-07%2017.59.57.jpeg?alt=media&token=12981dab-cfff-42c4-8c57-8ec3794fc3a7",
    alt: "Polycarbonate Signs",
    link: "contact",
    reverse: false,
    list: [
      {
        name: "20cm x 30cm",
        middle: "£12.00",
        description: "£6.50",
      },
      {
        name: "30cm x 40cm",
        middle: "£17.20",
        description: "£11.70",
      },
      {
        name: "40cm x 60cm",
        middle: "£27.40",
        description: "£22.20",
      },
    ],
  },
  {
    text: "Experience the ultimate in signage with our premium aluminium signs. Combining elegance and durability, these signs feature a high-quality finish and unparalleled resistance to corrosion. Ideal for upscale businesses, premium aluminium signs provide a sophisticated, long-lasting solution for your branding needs.",
    title: "Premium Aluminium Signs",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsigns%2Fal.png?alt=media&token=4be605e5-036f-4e07-af4a-07372997560d",
    alt: "Premium Aluminium Signs",
    link: "contact",
    single:true,
    reverse: false,
    list: [
      {
        name: "20cm x 30cm",
        middle: "",
        description: "£31.10",
      },
      {
        name: "30cm x 40cm",
        middle: "",
        description: "£55.30",
      },
      {
        name: "40cm x 60cm",
        middle: "",
        description: "£104.99",
      },
    ],
  },
  {
    text: "Set your business apart with our exquisite premium stainless steel signs. Offering a sleek, modern appearance and unmatched durability, these signs are resistant to rust, corrosion, and weathering. Make a lasting impression with the enduring elegance of stainless steel signs, and watch your business shine.",
    title: "Premium Stainless Steel Signs",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsigns%2F122.png?alt=media&token=86d98f0c-4c89-4bff-bb84-9a8b2282f867",
    alt: "Premium Stainless Steel Signs",
    link: "contact",
    reverse: false,
    single:true,
    list: [
      {
        name: "20cm x 30cm",
        middle: "",
        description: "£30.50",
      },
      {
        name: "30cm x 40cm",
        middle: "",
        description: "£50.10",
      },
      {
        name: "40cm x 60cm",
        middle: "",
        description: "£85.92",
      },
    ],
  },
];
export default class Signs extends Component {
  render() {
  logEvent(this.props.anal, 'signs_visited');
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          {signs_data.map((item) => (
            <div key={item.title}>
              <ProductPresent
                text={item.text}
                title={item.title}
                img={item.img}
                alt={item.alt}
                link={item.link}
                reverse={item.reverse}
              />
              {item.list && <Specs list={item.list} price={true} signs={true} h=' ' single={item.single} />}
              <Divider  style={{marginTop:'0px'}}/>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}
