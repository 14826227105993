import React, { Component } from "react";
import "./Top.css";
export default class Top extends Component {
  render() {
    return (
      <div>
        <div className="top-container">
          <div className="top-mini">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Ftop3.png?alt=media&token=cf8fc2c6-be75-4863-8f6f-0db803469315"
              }
              alt="excellent client service"
              className="top-img"
            ></img>
            <h3>Customer Focused</h3>
            <p style={{ textAlign: "left" }}>
              Our company thrives on understanding and anticipating customer
              needs, creating solutions that exceed expectations. and
              prioritizing client needs over a one-size-fits-all product
              approach.
            </p>
          </div>
          <div className="top-mini">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Ftop2.png?alt=media&token=7335daf4-2b99-403e-aaae-0b4586feab60"
              }
              alt="High quality premium materials"
              className="top-img"
            ></img>
            <h3>Highest Quality</h3>
            <p style={{ textAlign: "left" }}>
              Being backed up buy a factory equipped with advanced technologies,
              we consistently deliver top-tier quality without compromise,
              making exceptional products affordable for our clients.
            </p>
          </div>
          <div className="top-mini">
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Ftop1.png?alt=media&token=47709c86-3cdd-4fd2-bf66-cdb3418171c3"
              }
              alt="Complete personalisation"
              className="top-img"
            ></img>
            <h3>Total Personalisation</h3>
            <p style={{ textAlign: "left" }}>
              At our company, we specialize in exceptional personalisation,
              providing an extensive range of materials and services to
              accommodate any business needs.
            </p>
          </div>
        </div>{" "}
      </div>
    );
  }
}
