import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import PresentCarousel from "../blocks/PresentCarousel/PresentCarousel.js";
import Specs from "../blocks/Specs/Specs";

export default class Technical extends Component {
  render() {
    return (
      <div>
         <div className="tpc">
        <Header db={this.props.db} categories={this.props.categories} />
        <h1 style={{ textAlign: "center", marginTop: "40px" }}>
          Technology and Capabilities
        </h1>

        <PresentCarousel
          altList={["Laser printing", "Laser printing", "Laser printing"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F1%2FAccurio-PRESS-C83hc.jpg?alt=media&token=e9556443-a4cb-4570-8291-2fcb5575756a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F1%2F1215.jpg?alt=media&token=3fe27eeb-37ce-4325-bc01-e4a6d77e93b6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F1%2FPIWE8H0.jpg?alt=media&token=ceb77e71-f072-4c24-882e-f406a683accb",
          ]}
          title={"Laser printing"}
          text={
            "Digital color press designed for on-demand print environment. High printing speed of 71/60 color pages per minute. Exceptional image quality. Outstanding color clarity and precision at 1200x1200 dpix resolution."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "1200x1200 dpi",
            },
            {
              name: "Format",
              description: "A3",
            },
            {
              name: "Paper weight",
              description: "62-350g/mp",
            },
            {
              name: "Printing copy process",
              description: "Laser color",
            },
            {
              name: "Laser System",
              description:
                "Automatic duplex printing up to a maximum length of 762mm.",
            },
          ]}
        />

        <PresentCarousel
          altList={["Zund", "Zund", "Zund", "Zund", "Zund", "Zund", "Zund"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2FCiMSeptember16News-Zu%CC%88nd.jpg?alt=media&token=85680507-67f5-4034-b0ba-3a41c5244ebb",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2FRM_L_schr__g_Acrylplatte_01.588a07b395049.jpg?alt=media&token=74b46cfe-b4f1-4888-bdb2-4ae1d67107ed",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2F1.jpg?alt=media&token=80069d0f-cd6b-4b45-8315-047ed335c725",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2F2.jpg?alt=media&token=baf72269-bf89-4956-a288-014c4ef8d8e4",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2F3.jpg?alt=media&token=723d313c-2111-442a-ad47-b6988b132080",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2Fpiele5-_-1545912253.jpg?alt=media&token=f374fc8b-e739-48b5-b37e-67ea80d44909",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F2%2FP2270004web-845x684.jpg?alt=media&token=e7b281e0-0c60-4329-a8c4-d0b0e3a83563",
          ]}
          title={"Zund"}
          text={
            "The Swiss-made Zund equipment retains the same traits of versatility, reliability, and fast operation, offering maximum workflow optimization. The cutting materials can have thicknesses of up to 50mm, and the options for choosing modules, work table sizes, and material handling are extremely diverse. The unmatched adaptability to various operations is due to the flexibility of the modular concept of tools and working heads with a wide range of materials."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Cutting surface dimensions",
              description: "1600 x 1800 mm",
            },
            {
              name: "Materials",
              description:
                "Aluminium, Vinyl, Rubber, Wood, Cardboard, Plastic, Leather, Paper, Foam, Textile, Composite.",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Large format UV printer",
            "Large format UV printer",
            "Large format UV printer",
            "Large format UV printer",
            "Large format UV printer",
            "Large format UV printer",
            "Large format UV printer",
          ]}
          list={[
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2FUJV55-320-v2.jpg?alt=media&token=fedbf087-357d-45e5-bc64-c85e5ed52929",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2FBANNER_BLOCKOUT_9-_-1537368555.jpg?alt=media&token=b59aa471-f55c-4922-958b-2da01b1159ad ",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbacklite_12-_-1539001020.jpg?alt=media&token=f2b3577f-9da1-4a32-91b1-e1e38dae49ab",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbacklite_9-_-1539001018.jpg?alt=media&token=684127a1-dc52-46ec-b902-4b95264ed849",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbanner_3-_-1538741982.jpg?alt=media&token=6b7c17f9-68ba-4b97-a109-2b60891da606",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Fbanner_6-_-1538741983.jpg?alt=media&token=f2902444-c466-4e38-a3ae-0489b0fd0368",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F3%2Ftextil4-_-1551367944.jpg?alt=media&token=22713d36-aaa9-4366-bbf4-863a27d720df",
          ]}
          title={"Large format UV printer"}
          subtitle={"Printable width: 3.20 meters"}
          text={
            "Large format printing involves printing on material up to 3.2 meters wide, with the length of the printed material determined only by the length of the roll. It is mostly used for outdoor advertising. Large format printing is applied to: ceilings, flags, construction nets, light boxes, materials for interior and exterior decorations, floor graphics, street banners, and more."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "300, 450, 600, 900, 1,200 dpi",
            },
            {
              name: "Maximum printing area",
              description: "3200 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Mimaki CJV300",
            "Mimaki CJV300",
            "Mimaki CJV300",
            "Mimaki CJV300",
            "Mimaki CJV300",
            "Mimaki CJV300",
            "Mimaki CJV300",
            "Mimaki CJV300",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2F1.jpeg?alt=media&token=490253df-11b0-473d-ad2f-f630963ce856 ",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2Fautocolant_decupare_3-_-1539355905.jpg?alt=media&token=c9fb0a20-9f6d-48db-95e9-7ac65bc1f391",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2Fautocolant_decupare_5-_-1539355906.jpg?alt=media&token=1994d1ad-a2c3-4951-8ded-09c2479a4424",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2Fsticker.jpg?alt=media&token=aa6868b8-e1f4-476b-afa9-e2f31091f101 ",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_1-_-1547732387.jpg?alt=media&token=453fd690-3b51-4337-82b5-574e4660e468",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_13-_-1547732394.jpg?alt=media&token=8d13f1ed-7670-4a5a-995b-4cff297dc582",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_5-_-1547732388.jpg?alt=media&token=334b7b88-1327-4ad7-a83b-e90d99150c57 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F4%2FAUTOCOLANTA_9-_-1547732390.jpg?alt=media&token=0e68ad19-8a48-47e1-ab6e-a130c8ea68d3",
          ]}
          title={"Mimaki CJV300"}
          text={
            "This model achieves the highest printing speeds in its class, thanks to innovative technologies that allow for amazing quality. It prints quickly at speeds up to 105.9 m2/h, with high-quality printing resolution (up to 1,440 dpi) and a printing width of 1,610 mm. Digital printing is done on a wide range of media supports, making it suitable for a variety of applications, especially for digital prints."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "up to 1.440 dpi",
            },
            {
              name: "Ink system",
              description: "CMYK Lc, Lm,Lk,Or (SS21) și CMYK (BS3/BS4)",
            },
            {
              name: "Width",
              description: "up to 1.361 mm /1.610 mm",
            },
            {
              name: "Printing speed",
              description: "up to 105,9 m2/oră",
            },
            {
              name: "Thickness",
              description: "up to 1.0 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "UV printer with rotary system",
            "UV printer with rotary system",
            "UV printer with rotary system",
            "UV printer with rotary system",
            "UV printer with rotary system",
            "UV printer with rotary system",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FUJF_3042.jpg?alt=media&token=02e2f845-9e5e-4ebe-88eb-05274da9e926",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_1-_-1538993088.jpg?alt=media&token=ccc07251-e7e9-40b6-b317-d62ca85a905e",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_10-_-1538993093.jpg?alt=media&token=54e6d679-0f4f-4ad1-85a2-6eb892578985",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_11-_-1538993093.jpg?alt=media&token=a65ffda5-f6ba-46a6-b1d4-f5c73fd1cad5",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_2-_-1538993089.jpg?alt=media&token=21717906-e343-4842-8da2-a67fc73e454d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_3-_-1538993089.jpg?alt=media&token=fed420f1-8d32-42d5-8a63-10fc968f3aad",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F5%2FPRODUSE_PROMO_5-_-1538993092.jpg?alt=media&token=7363d1af-0b28-490c-845c-4af3efe035e4",
          ]}
          title={"UV printer with rotary system"}
          text={
            "UV printing offers a unique solution for small print runs. These printers offer unparalleled versatility and high-quality printing reproduction on an almost unbelievable range of materials, products, and substrates. This particular model can print images on a wide variety of materials, including glass, acrylic, PVC sheets, ceramic materials, Aluminium, metal, wood, MDF, paper, canvas, leather, wallpaper, stickers, plastic, and more. It can also be used for personalisation on promotional products such as laptops, mobile phones, car air fresheners, gifts, rulers, signage, wooden plaques, trophies, industrial control panels, adhesive films, and more."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "720 × 600 dpi, 1,440 × 1200 dpi",
            },
            {
              name: "Maximum printing area",
              description: 'Width: 300 мм (11.8") Length: 420 мм (16.5")',
            },
            {
              name: "Material thickness",
              description: 'Max. 50 мм (1.97")',
            },
            {
              name: "UV System",
              description: "LED-UV (bec led)",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Textile printing by sublimation",
            "Textile printing by sublimation",
            "Textile printing by sublimation",
            "Textile printing by sublimation",
            "Textile printing by sublimation",
            "Textile printing by sublimation",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2F6597dee80f.jpg?alt=media&token=2ab57f60-ed1d-4af8-b6a7-23237a4da08c ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_3-_-1539070663.jpg?alt=media&token=9ed8f1d5-d3de-46e9-b48c-77105c15c840 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_2-_-1539070662.jpg?alt=media&token=e210d0de-020c-4f49-98fe-fcd5362d488f ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_5-_-1539070663.jpg?alt=media&token=0f40e1b0-3e51-4e47-9bf9-119685093182 ",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_6-_-1539070663.jpg?alt=media&token=74e0d75c-6807-4293-a26a-2e9f955c332b",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F6%2Ffashon_7-_-1539070663.jpg?alt=media&token=ebac9340-afa8-454d-89f5-5edac44107f9 ",
          ]}
          title={"Textile printing by sublimation"}
          text={
            "Sublimation is a printing process done with special inks that, through heat, transition directly from solid to gas state without going through a liquid state. Among the areas of use for sublimation printing are: advertising industry, textile and fashion industry, graphic arts, entertainment industry, interior decorations, and upholstery. Sublimation works only for personalisation on textiles with at least 80% polyester content and for materials pre-treated for sublimation."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "300, 450, 600, 900, 1,200 dpi",
            },
            {
              name: "Maximum printing length",
              description: "1940 mm",
            },
            {
              name: "Maximum printing width",
              description: "1950 mm",
            },
            {
              name: "Maximum thickness",
              description: "1.0 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Direct textile printing.",
            "Direct textile printing.",
            "Direct textile printing.",
            "Direct textile printing.",
            "Direct textile printing.",
            "Direct textile printing.",
            "Direct textile printing.",
            "Direct textile printing.",
          ]}
          list={[
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2F1.jpg?alt=media&token=9dcf0899-5a32-465a-9927-8602b3b39d3a",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_1-_-1539096787.jpg?alt=media&token=4c79b654-7400-4c85-b521-be39153eaead",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_10-_-1539096790.jpg?alt=media&token=042bc397-cc14-4d89-b152-810f75c3f5cb",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_2-_-1539096788.jpg?alt=media&token=fa7717a0-399d-41ca-b9e9-695c980c4d85",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_4-_-1539096788.jpg?alt=media&token=cb466229-54b3-4932-9d21-5209df67529f",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_5-_-1539096788.jpg?alt=media&token=097a5ffd-7540-447a-8c09-7fa66536e7a2",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_8-_-1539096789.jpg?alt=media&token=8026ffbf-9676-4d4d-81b9-54e8da01d7e6",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F7%2FHOMEDECOR_9-_-1539096790.jpg?alt=media&token=8ef19207-d104-462d-8a5a-93db126e85f0",
          ]}
          title={"Direct textile printing."}
          text={
            "In direct printing, the ink is placed directly on the pre-treated material. The applications of digitally printed textiles are extremely diverse, including the production of clothing (with the creation of samples or small to medium volume series), sports articles, interior decorations, exhibition stands, wallpaper, curtains, light boxes, flags, outdoor textile banners, awnings and blinds, indoor textile banners, curtains, covers, tablecloths, fashion design, and more."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "360, 540, 720, 1080, 1440 dpi",
            },
            {
              name: "Maximum printing width",
              description: "1920 mm",
            },
            {
              name: "Material max thickness",
              description: "1.0 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Roll lamination",
            "Roll lamination",
            "Roll lamination",
            "Roll lamination",
            "Roll lamination",
          ]}
          list={[
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2F1.jpg?alt=media&token=515ce4ae-18d1-4800-9137-17dc3dca85ac",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_LAMINAT2_2-_-1539942052.jpg?alt=media&token=593e6958-2716-4add-9929-6b8249fd018f",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_LAMINAT2_3-_-1539942052.jpg?alt=media&token=ca3ba6cb-714f-408e-9fe2-c55ff5bc9a14",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_LAMINAT_2-_-1539872349.jpg?alt=media&token=b50f6173-a2b2-4a20-ad50-a01941fd56ce",
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F8%2FAUTOCOLANT_laminat_1-_-1539872348.jpg?alt=media&token=95cb06f1-238f-4c0e-88e0-2bd9547c8f54",
          ]}
          title={"Roll lamination"}
          text={
            "The lamination process involves bonding two or more layers together to protect the print. During the process, a film layer is passed over a heated roller and, with reasonable pressure, adheres to the print. Roll lamination provides an exceptional appearance to the print and increased resistance to mechanical action, making it ideal for laminating advertising prints."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Format",
              description: "+ А0",
            },
            {
              name: "Type",
              description: "Hot",
            },
            {
              name: "Heating temperature",
              description: "140 °C",
            },
            {
              name: "Lamination width",
              description: "1650 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={["Plastic bending", "Plastic bending", "Plastic bending"]}
          list={[
            " https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F9%2Fnai-nai.jpg?alt=media&token=8550f126-7c97-4c9f-aecf-b7ec6ad12b2a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F9%2Flea-lea-optimizat.jpg?alt=media&token=03e08c32-2317-4aaa-84bb-79a02194724d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F9%2FV1.jpg?alt=media&token=b0be3274-5e2c-4030-992f-a6168b6d77ea",
          ]}
          title={"Plastic bending"}
          text={
            "The bending of plastics of different sizes and thicknesses is done with a tool that uniformly heats acrylic plates or any other thermoplastic material. The device is designed to quickly create perfectly straight bends in the production of self-supporting advertising signs and other plastic-based display structures."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Type",
              description: "Thermoforming",
            },
            {
              name: "Length",
              description: "1250 mm",
            },
            {
              name: "Width",
              description: "3-10 mm",
            },
            {
              name: "Temperatures",
              description: "0-600 °C",
            },
          ]}
        />

        <PresentCarousel
          altList={["Klieverik", "Klieverik", "Klieverik", "Klieverik"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2F1.jpg?alt=media&token=532a9825-f7ea-4233-82fa-c1c2f329cecc",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2Fb896048c5de60ba0_org.jpg?alt=media&token=049a77a6-d9db-4870-b928-dbbe7cf91e79",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2Fmaxresdefault.jpg?alt=media&token=ce279775-1cfa-42fb-833d-e752972d4775",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F10%2Fnnainai.jpg?alt=media&token=0217ce23-a473-49fb-819b-6cde33bdb2cc",
          ]}
          title={"Klieverik"}
          text={
            "The thermal sublimation technology involves transferring a multi-colored image from a digitally printed surface (special paper printed with sublimation ink) and transferring the image onto a polyester textile support using pressure and suitable temperatures for the process."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Width ",
              description: "1650 mm, 2100 mm, 3200 mm",
            },
            {
              name: "Speed",
              description: "0,1 – 2 m/min",
            },
            {
              name: "Max temperature",
              description: "220 C",
            },
          ]}
        />

        <PresentCarousel
          altList={["CNC Router", "CNC Router", "CNC Router", "CNC Router"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2F1.jpg?alt=media&token=a7aec2ca-3fbb-4ec6-b8d1-0c1dc1ef2376 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2FF.jpg?alt=media&token=ee5ff277-021f-4b25-ab87-f2185a7ccb75 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2Fc9bd5fd47213b75f6a8bf96bd3d9a99f.jpg?alt=media&token=acac7254-1692-4b1e-ac19-b48dc1d27272 ",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F11%2Fcompozit_1-_-1545916901.jpg?alt=media&token=a46588fd-ac76-4f33-ba00-bdd0ac6c242a ",
          ]}
          title={"CNC Router"}
          subtitle={"Cutting and engraving"}
          text={
            "The CNC router is used for cutting and engraving large format materials, for tracing materials, for cutting letters or other volumetric objects. The materials that can be cut include: plexiglass, PVC, ABS plastic, forex, wood, MDF, plywood, composite Aluminium, brass, stainless steel and other types of metals, dibond, etc."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Working area X, Y, Z",
              description: "1300 * 2500 * 200 mm",
            },
            {
              name: "Rotation speed",
              description: "600-18000RPM",
            },
          ]}
        />

        <PresentCarousel
          altList={["KINGSIGN", "KINGSIGN"]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F12%2F1.jpg?alt=media&token=a3550090-53ac-4a6f-8f75-bc22cda655a9",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F12%2F6-Easy-Steps-to-Get-the-Best-Finish-When-Cutting-Acrylic.jpg?alt=media&token=f13c91a5-bb23-49d0-a62c-56f472241867",
          ]}
          title={"KINGSIGN"}
          subtitle={"Engraving and milling"}
          text={
            "The CNC router is used for cutting and engraving large format materials, for tracing materials, and for cutting letters or other volumetric objects. The materials that can be cut include: plexiglass, PVC, ABS plastic, forex, wood, MDF, plywood, composite Aluminium, brass, stainless steel and other types of metals, dibond, alucobond, and more."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Working area X, Y, Z",
              description: "300 * 450* 15 mm",
            },
            {
              name: "Rotation speed",
              description: "600-18000RPM",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Cutting and laser engraving",
            "Cutting and laser engraving",
            "Cutting and laser engraving",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F13%2F1.jpg?alt=media&token=cab4f1bd-3b34-4eb4-9483-8744dae4d4fe",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F13%2Flc2.jpg?alt=media&token=a055bcdb-6eff-4222-918c-d41ae06f154a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F13%2Facrylic.jpg?alt=media&token=c896b4b2-1e35-42b2-8578-bd59ab3cfd16",
          ]}
          title={"Cutting and laser engraving"}
          text={
            "The laser cutting and engraving procedures can be extremely complex and delicate, providing durability and accuracy to the work. Various materials can be engraved or cut, including paper/cardboard, plastic (plexi, acrylic), wood, glass, ceramics, treated metal, Aluminium, marble and granite, leather, textile material, cork, foam, and more. Laser technology is used for personalised engraving on promotional items or signage elements, as well as for precise cutting and trimming of different materials."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Working area",
              description: "1300X900 mm and 600x900 mm",
            },
            {
              name: "Laser type",
              description: "CO2 laser",
            },
            {
              name: "Speed",
              description: "0-54000 mm/min",
            },
            {
              name: "Working temperature",
              description: "0-45 °C",
            },
            {
              name: "Laser power",
              description: "130 W",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Laser metal cutting",
            "Laser metal cutting",
            "Laser metal cutting",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F14.jpeg?alt=media&token=6195a929-0ab6-41ee-be71-6dab37b3d455",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F14%2Fwhat-it-looks-like-when-a-laser-is-cutting-through-3erp-200716.jpg?alt=media&token=53452099-ae80-4fc5-87c6-97461be31577",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F14%2FMy-Metal-Business-Card-Photography-Stainless-Steel-Card.jpeg?alt=media&token=565aacf4-8846-4e9d-a219-c525963fc7b8",
          ]}
          title={"Laser metal cutting"}
          text={
            "Thanks to laser technology, you can achieve the best cutting/engraving time for materials, with superior and clean quality. The laser works cleanly and quietly, and the cut material has smooth edges, with each detail having a perfect finish. Laser cutting procedures can be extremely complex and delicate, providing durability and accuracy to the work over time."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Working area size",
              description: "1300*900 mm",
            },
            {
              name: "Laser power",
              description: "1500 W",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Laser engraving",
            "Laser engraving",
            "Laser engraving",
            "Laser engraving",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2F1.jpg?alt=media&token=a605c12b-0bfb-454d-b7a1-f69976ca0dd4",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2FMetal%20Marking%20QR%20Code%20Tags.jpg?alt=media&token=5836c47f-a5a2-4066-adad-4a0afc59a924",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2Flelele-opt.jpg?alt=media&token=969382cf-1961-4dc7-8dbc-871d487667a6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F15%2Fmaxresdefault-2.jpg?alt=media&token=adc61678-97ec-4fa5-abfc-f41d57a2b824",
          ]}
          title={"Laser engraving"}
          text={
            "The fiber optic laser marking machine is used to mark various objects, such as bar-codes, serial numbers, dates, product names, different graphics, and patterns on metal, plastic, and other materials. Different objects can be personalised, such as keyboards, kitchen utensils, auto accessories, plastic buttons, glass, medical equipment, watches, metal objects, and more. Its main features are high speed and efficiency, being 2-3 times faster than traditional methods."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Laser wavelength",
              description: "1060mm",
            },
            {
              name: "Marking scale",
              description: "110mm x110mm",
            },
            {
              name: "Minimum font",
              description: "0.15mm",
            },
            {
              name: "Engraving liner speed",
              description: "7000mm/s",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "3D laser engraving",
            "3D laser engraving",
            "3D laser engraving",
            "3D laser engraving",
            "3D laser engraving",
            "3D laser engraving",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F16.jpeg?alt=media&token=e3026133-5bf0-4b26-91e0-0715d065903d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F0069981c3c4f00facac5ee38f629e0d9.jpg?alt=media&token=3bd7f7a3-f4fe-4db2-8331-b50f254f8a59",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F131323123.jpg?alt=media&token=a0fc2b92-5a55-4007-819b-91b2b1d70ca6",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F972d4b7cbe3fd0d4523351ee8eb17f19.jpg?alt=media&token=07b3ec9d-3969-4588-9c97-31bd9750d129",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2Fa225fa1a937c0c2e3a0da016a8da4ab9.jpg?alt=media&token=41192613-f7cc-4798-82a6-1f120ae9c157",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F16%2F0e260c89af47ef7ee3cdbfa9bd639f46.jpg?alt=media&token=e7bc85ee-074b-4f5a-9708-b3dc5df28fa1",
          ]}
          title={"3D laser engraving"}
          text={
            "The fiber optic laser marking machine has the advantage of being able to perform engraving not only on flat objects but also on volumetric objects. The laser is suitable for personalising promotional items made of solid or flexible materials, with exact precision."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Laser thickness",
              description: "<0.3mm",
            },
            {
              name: "Precision",
              description: "±0.003mm",
            },
            {
              name: "Speed",
              description: "8000mm/s",
            },
            {
              name: "Font size",
              description: "0.5-110mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Total Promote",
            "Total Promote",
            "Total Promote",
            "Total Promote",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F1.png?alt=media&token=09fa7913-43dd-4cb9-ad93-14721c4ea065",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F0d898dc43e5681dc296905c672838ae3.jpg?alt=media&token=a88547df-614a-4c26-a46a-d4f2f8a336f7",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F92a10bb1fb44116aee409c88992dafdd.jpg?alt=media&token=c01fffc5-c4c3-4003-a158-2132475525d9",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F17%2F070c5a7d02d2e31523e5f4f9d7fe0e7a.jpg?alt=media&token=6a15bf3a-cca9-488a-84aa-13cba7088b6b",
          ]}
          text={
            "The UV laser marking machine is the first UV laser marking machine with three-axis control technology, capable of marking on a robust, circular, and other special shaped surfaces."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Thickness laser",
              description: "<0.3mm",
            },
            {
              name: "Precision",
              description: "±0.003mm",
            },
            {
              name: "Speed",
              description: "8000mm/s",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Total Promote",
            "Total Promote",
            "Total Promote",
            "Total Promote",
            "Total Promote",
            "Total Promote",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F18.jpeg?alt=media&token=4b104c5e-ec62-4896-94ad-d6ffb01ecce4",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2F8303cb38610c4dbbbc7bfdb4b2978dd2.jpg?alt=media&token=76ce8e30-62d1-4bf5-9990-d4338750f28a",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2F131313.jpg?alt=media&token=4ffdafbe-b670-4740-aef4-5f2c7e34b800",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2Fmaxresdefault-2.jpg?alt=media&token=eaddfcce-917f-4d95-9b8d-c023f368a62b",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2Fwholesale-new-design-best-price-free-logo-paper-laser-cut-baby-naming-ceremony-invitation-cards.jpg?alt=media&token=2b9495fe-3201-4232-bb19-80ecf0cfe7d8",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F18%2F50pcs-wholesale-laser-cut-invitatios-wedding.jpg?alt=media&token=6d90c136-e2f7-4a62-bcaf-4a3b5e371cfe",
          ]}
          title={"Engraving"}
          text={
            "This model has high efficiency, with a large marking area and a fine laser beam for precise and fast processing. It can be used on various materials such as acrylic, PVC, rubber sheet, plastic, crystal, glass, wood, bamboo, oxhorn, figurine, marble, leather, canvas, double plate, and more."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Engraving area",
              description: "600 x 600 mm",
            },
            {
              name: "Precision",
              description: "±0.001 mm",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "Polishing machine",
            "Polishing machine",
            "Polishing machine",
            "Polishing machine",
            "Polishing machine",
            "Polishing machine",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2Fupdated%2F19.jpeg?alt=media&token=9b63b191-79fa-42a4-b019-42416105ac3f",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2Fc117659faa9322c7acf085381a7aac21.jpg?alt=media&token=eb11f9f8-4fb7-47e9-a824-6d6ea4874178",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2F1071462a6cc952d437801626b8e3e445.jpg?alt=media&token=553bd151-e4c2-4ef4-8976-16a7a4a19f6d",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2F3b3a5a9a72f6bffbfe79367414cb66de.jpg?alt=media&token=38b2dd26-b8a4-458b-be59-cab216df75c8",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F19%2F94ede3c5878cf92ecf454772d01e2724.jpg?alt=media&token=169214d8-817d-49ef-a9a0-dc3ed32847fc",
          ]}
          title={"Polishing machine"}
          text={
            "Acrylic and plexiglass objects are easily scratched, leading to an unsightly and worn appearance. This situation is frequently encountered with acrylic components. A simple and efficient solution to this is polishing the materials, which is commonly used for trophies, souvenirs, and various products made of organic glass."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Working zone size",
              description: "1000mm(L)×100mm(T) (extensible)",
            },
            {
              name: "Speed",
              description: "0-1m/min",
            },
            {
              name: "Working angle",
              description: "0-60 degrees",
            },
          ]}
        />

        <PresentCarousel
          altList={[
            "UV Printer",
            "UV Printer",
            "UV Printer",
            "UV Printer",
            "UV Printer",
            "UV Printer",
            "UV Printer",
            "UV Printer",
          ]}
          list={[
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2F1.jpg?alt=media&token=98cd92af-a3db-4f62-8230-8ea80ca434d2",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2FPVC_1-_-1538380839.jpg?alt=media&token=938f14c3-7605-47da-ab8f-5b1c964b252b",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2FPVC_10-_-1538380839.jpg?alt=media&token=fe6c034e-dd67-41c6-a4ef-29d983d86113",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2FPVC_7-_-1538380838.jpg?alt=media&token=933cc855-bae7-439c-849d-6b5a17a7444c",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fimprimare_PAL_1-_-1538404341.jpg?alt=media&token=e2f99492-d906-4b1b-84df-9cc523a5da53",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fplacaj_4-_-1538465928.jpg?alt=media&token=379d0a8c-6c25-4a37-b58d-c5a551b69099",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fsticla_silicata_11-_-1538400714.jpg?alt=media&token=f48aea82-344d-480c-b304-4e3a69a3e955",
            "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/baza_tehnica%2F20%2Fsticla_silicata_4-_-1538400711.jpg?alt=media&token=3d644a0b-99e6-489b-911f-b2a9d7145177",
          ]}
          title={"UV Printer"}
          text={
            "UV printing offers a unique solution for small print runs. These printers provide unparalleled versatility and high-quality printing reproduction on a wide range of materials, products, and substrates. The given model can print images on a variety of materials such as glass, acrylic, PVC sheets, ceramic material, Aluminium, metal, wood, MDF, paper, canvas, leather, wallpaper, stickers, plastics, and more. Personalisation is possible on promotional products like laptops, mobile phones, car air fresheners, gifts, rulers, signs, wooden plaques, trophies, control panels for industries, self-adhesive films, and more."
          }
        ></PresentCarousel>
        <Specs
          tech={true}
          list={[
            {
              name: "Resolution",
              description: "720 × 600 dpi, 1,440 × 1200 dpi",
            },
            {
              name: "Maximum printing area",
              description: 'Width: 1300 мм (11.8") Length: 2500 мм (16.5")',
            },
            {
              name: "Material thickness",
              description: 'Max. 50 мм (1.97")',
            },
            {
              name: "UV System",
              description: "LED-UV",
            },
          ]}
        />
        </div>
        <Footer />
      </div>
    );
  }
}
