import React, { Component } from "react";
import { Input, message } from "antd";
import { collection, addDoc } from "firebase/firestore";

import Footer from "../Footer";
import Header from "../Header";
import "./pages.css";

const { TextArea } = Input;

var containerStyle = {
  width: "420px",
  margin: "50px auto",
};
export default class Contact extends Component {
  state = {};
  componentDidMount = async () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  createEmail = async () => {
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const comments = document.getElementById("comments").value;

    if (name.length < 3) {
      message.warning("Please introduce yourself.");
      return;
    }
    if (email.length < 10 || !email.includes("@") || !email.includes(".")) {
      message.warning(
        "We will need a valid email address to be able to contact you back."
      );
      return;
    }

    var email_text =
      "<b>Name: </b> " +
      name +
      "<br/><b>Email: </b>" +
      email +
      "<br/><b>Message: </b>" +
      comments +
      " .";
    const docRef = await addDoc(collection(this.props.db, "contact"), {
      name: name,
      email: email,
      message: comments,
    });
    console.log("Pre-Order ID: ", docRef.id);
    this.sendIt(email_text, email);
  };

  sendIt = async (text, email) => {
    // Add a new document with a generated id.
    const order = await addDoc(collection(this.props.db, "mail"), {
      to: ["total.promote.ltd@gmail.com"],
      message: {
        subject: `Contact - ${email}`,
        html: text,
      },
    });
    console.log("Order accepted with ID: ", order.id);
    const thanks = await addDoc(collection(this.props.db, "mail"), {
      to: [email],
      message: {
        subject: `Contact | Total Promote`,
        html: `<h5> Thanks for reaching out, we'll get back to you ASAP!</h5><p>Your message: "${text}"</p>
              <p>Don't hesitate to contact us directly at: <a href='mailto:info@totalpromote.co.uk'>info@totalpromote.co.uk</a> for any other enquires or questions.</p>
              <h5>Kind Regards, Total Promote Team.</h5>`,
      },
    });
    console.log("Confirmation sent. ID: ", thanks.id);
    document.getElementById("name").value = " ";
    document.getElementById("email").value = " ";
    document.getElementById("comments").value = " ";
    message.success("Thanks for reaching out! We will get back to you ASAP!");
  };

  render() {
    if (this.state.mobile) {
      containerStyle = {
        width: "300px",
        margin: "20px auto",
      };
    }
    return (
      <div>
        <Header categories={this.props.categories} />
        <div style={containerStyle}>
          <h1 className="sub-h">Contact</h1>
          <label>Name:</label>
          <Input className="contact-input" id="name"></Input>
          <label>Email:</label>
          <Input className="contact-input" id="email"></Input>
          <label>How can we help?</label>
          <TextArea className="contact-input" rows={4} id="comments"></TextArea>
          <button
            className="hero-button contact-btn"
            onClick={() => this.createEmail("Now")}
          >
            Submit
          </button>
        </div>
        <Footer />
      </div>
    );
  }
}
