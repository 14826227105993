import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";
import ImageText from "../blocks/ImageText/ImageText";
import Top from "../blocks/Top/Top";

export default class About extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          <ImageText
            title="About us"
            text="Total Promote LTD is a new UK-based business offering custom-designed banners, badges, signs, stickers, flags and other custom-made products for SMEs,
             event organisers, individuals and despite being a small new company, Total Promote is backed up by one of the most advanced factory in Europe and has the capabilities to fulfil 
             large orders ensuring high-quality products at competitive prices."
            img="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fabout.png?alt=media&token=341f20fa-24aa-4712-98c0-3f65c3318477"
          />
          <Top />
        </div>
        <Footer />
      </div>
    );
  }
}
