import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ProductPresent from "../blocks/ProductPresent/ProductPresent";
import Specs from "../blocks/Specs/Specs";
import { Divider } from "antd";
import { logEvent } from "firebase/analytics";
const stickers_data = [
  {
    text: `Upgrade your business's visibility with our high-quality car stickers and decals. Perfect for branding, promotions, or advertising, our durable and eye-catching designs will make your vehicles stand out. Drive new customers to your website and increase sales with these cost-effective marketing tools. Discover our extensive range of customisable options today!`,
    title: "Car Stickers and Decals",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fstickers%2Fcar%20stickers.png?alt=media&token=0ed0d11d-18ab-4a31-9d4a-a26c9b1b5999",
    alt: "Car Stickers",
    link: "contact",
    id: "vinyl",
    price: "21.70 / m²",
    reverse: false,
  },
  {
    text: `Maximize your business's exposure with our perforated window decals, allowing for eye-catching designs without obstructing the view. Ideal for vehicle or store windows, these decals create a professional and engaging look. Attract more customers and drive traffic to your website by choosing from our wide selection of customisable designs. Enhance your brand visibility now!`,
    title: "Perforated Window Decals",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fstickers%2Fperforated%20sticker.png?alt=media&token=a3b87814-5792-4168-ba5f-b1dfb5f3d11e",
    alt: "Perforated window and car sticker.",
    link: "contact",
    id: "vinyl",
    price: "24.80 / m²",
    reverse: true,
  },
  {
    text: `Transform your storefront with our stunning window decals, designed to capture the attention of potential customers. These easy-to-apply and remove decals are perfect for showcasing promotions, events, or brand messaging. Increase foot traffic and boost sales by enhancing your business's visual appeal. Browse our diverse collection to find the perfect design for your brand.`,
    title: "Window Stickers and Decals",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fstickers%2Fwindow%20sticker.png?alt=media&token=2276e532-12e7-464e-9913-68b94f421942",
    alt: "Window Stickers",
    link: "contact",
    id: "vinyl",
    price: "21.70 / m²",
    reverse: false,
  },
  {
    text: `Revamp your office or retail space with our versatile wall stickers and decals. Create a lasting impression on clients and customers by showcasing your brand's identity in a stylish and professional manner. Boost sales and promote your products or services with these high-quality, custom-designed decals. Explore our vast range of designs to elevate your business environment.`,
    title: "Wall Stickers and Decals",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fstickers%2Fwall%20stick.png?alt=media&token=219da30f-ff25-4f47-a609-63635f30bd82",
    alt: "Wall Stickers",
    link: "contact",
    id: "vinyl",
    price: "21.70 / m²",
    reverse: true,
  },
];
export default class Stickers extends Component {
  render() {
      logEvent(this.props.anal, 'stickers_visited');
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          <h1 className="sub-h">Stickers & Decals</h1>
          <ProductPresent
            text="Enhance your product packaging and brand recognition with our small format stickers and labels. Perfect for businesses looking to make a lasting impact, these cost-effective marketing tools will help drive sales and direct customers to your website. With a variety of materials and designs available, find the perfect sticker or label to elevate your brand's appeal."
            title="Small Stickers and Labels"
            img="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fstickers%2FStickere.png?alt=media&token=8f892ffe-be58-43b6-b912-413a4c237a88"
            alt="customised stickers and labels"
            link="contact"
            reverse={false}
          />
          <Specs
            list={[
              {
                name: "5 x 7 cm",
                middle: "£9.99",
                description: "£75.95",
              },
              {
                name: "7 x 10 cm",
                middle: "£18.99",
                description: "£151.90",
              },
              {
                name: "10 x 15 cm",
                middle: "£35.80",
                description: "£325.50",
              },
              {
                name: "Other sizes:",
                middle: "Contact us for prices",
                description: "",
              },
            ]}
            price={true}
            labels={true}
          />
          <Divider  style={{marginTop:'0px'}}/>
          {stickers_data.map((item) => (
            <div key={item.title}>
              <ProductPresent
                text={item.text}
                title={item.title}
                img={item.img}
                alt={item.alt}
                link={item.link}
                reverse={item.reverse}
                price={item.price}
              />
              <Divider  style={{marginTop:'0px'}}/>
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}
