import { Carousel } from "antd";
import React, { Component } from "react";
import { hero1, hero2, hero3 } from "./content";
import './styles.css'

export default class Hero extends Component {
  render() {
    const mobile = this.props.mobile
    return (
      <div>
        <Carousel autoplay>
          {hero1(mobile)}
          {hero2(mobile)}
          {hero3(mobile)}
        </Carousel>
      </div>
    );
  }
}
