import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ProductPresent from "../blocks/ProductPresent/ProductPresent";
import { Divider } from "antd";
import { logEvent } from "firebase/analytics";
const promotional_flags = [
  {
    text: "Boost your brand visibility with our high-quality feather/sail flags, designed for maximum exposure in any setting. Lightweight and easy to assemble, these flags ensure your message reaches your target audience. Perfect for events, promotions, and outdoor advertising. Stand out with feather flags!",
    title: "Feather Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fsail%20small.png?alt=media&token=e625b5d7-0027-42d3-a19e-bea552c4e7c9",
    alt: "Feather and sail flags",
    link: "contact",
    price: "125.70",
    reverse: false,
  },
  {
    text: "Attract attention with our sleek teardrop flags, crafted to make your brand's logo and message shine. Designed for durability and effortless installation, these flags are perfect for indoor and outdoor use. Capture your audience's attention with the elegant, aerodynamic shape of teardrop flags.",
    title: "Teardrop Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fteardrop%20small.png?alt=media&token=e9de6122-cb8a-4c43-b739-c619d25ebefd",
    alt: "Teardrop flags",
    link: "contact",
    price: "127.80",
    reverse: true,
  },
  {
    text: "Promote your business with our versatile banner flags. These flags offer a prominent display, ensuring your message is seen from afar. Easy to set up and available in various sizes, banner flags are ideal for trade shows, storefronts, and events. Enhance your brand presence with our eye-catching banner flags.",
    title: "Banner Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fbanner_flag%20small.png?alt=media&token=9a1ff5b5-ab89-4188-8be3-ad964f92c749",
    alt: "Banner flags",
    link: "contact",
    price: "133.30",
    reverse: false,
  },
];

const national_flags = [
  {
    text: " Show your patriotism and unity with our durable national flags. Made with premium materials, these flags are ideal for schools, businesses, and public institutions. Display your national pride at sporting events, holidays, or any special occasion. Fly your flag high and make a statement!",
    title: "Standard National Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fukflag.png?alt=media&token=352fefc0-94cb-4802-af40-1472fc84bd50",
    alt: "National flags",
    link: "contact",
    price: "39.30",
    reverse: true,
  },
  {
    text: " Experience the luxury of our premium atlas national flags, crafted from top-quality materials for a sophisticated appearance. The elegant, silky texture of the atlas fabric ensures longevity and vibrant colors, making these flags perfect for high-end events and formal settings. Elevate your flag display with our premium range.",
    title: "Premium National Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fuk-royal-crown-great-britain-flag-background.jpg?alt=media&token=f2abbb47-76d5-439c-a0a2-3f253b382f82",
    alt: "Premium National flags",
    price: "168.70",
    link: "contact",
    reverse: false,
  },
  {
    text: "Enhance your international events with our striking table national flags. Perfect for conferences, meetings, and cultural celebrations, these flags are compact and tasteful. Showcase your global connections with pride, using our easy-to-display, high-quality table flags.",
    title: "National Table Flags",
    img: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflags%2Fflags-g7-group-seven-list-countries-canada-usa-states-germany-italy-france-japan-3d-rendering.jpg?alt=media&token=bf021a01-3f4c-4dd1-8930-dd8c64c4d595",
    alt: "National table flags",
    link: "contact",
    price: "2.80",
    reverse: true,
  },
];
export default class Flags extends Component {
  render() {
    logEvent(this.props.anal, "flags_visited");
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          <h1 className="sub-h">Promotional Flags</h1>
          {promotional_flags.map((item) => (
            <div key={item.title}>
              <ProductPresent
                text={item.text}
                title={item.title}
                img={item.img}
                alt={item.alt}
                link={item.link}
                reverse={item.reverse}
                price={item.price}
              />
              <Divider />
            </div>
          ))}
          <h1 className="sub-h">National Flags</h1>

          {national_flags.map((item) => (
            <div key={item.title}>
              <ProductPresent
                text={item.text}
                title={item.title}
                img={item.img}
                alt={item.alt}
                link={item.link}
                reverse={item.reverse}
                price={item.price}
              />
              <Divider />
            </div>
          ))}
        </div>
        <Footer />
      </div>
    );
  }
}
