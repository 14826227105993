export const hero1 = (mobile) => {
  if (mobile) {
    return (
      <div className="hero hero1">
        <div style={{ backgroundColor: "#F2E3DB" }}>
          <img
            alt="Banners"
            className="hero-image"
            src="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fhero1_1.png?alt=media&token=f0a8ab99-8fa5-4c9c-a610-1ea9f1bc428e"
          />
        </div>
        <div style={{ backgroundColor: "#F2E3DB", margin: "auto" }}>
          <h1 className="hero-h">Promotional Banners</h1>
          <h2 className="hero-p">
            Boost your brand visibility with our high-quality, weather-resistant
            banners. A large variety of banners that will fulfil any business or
            event needs.
          </h2>
          <a href="/banners">
            <button className="hero-button">Browse Now</button>
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="hero hero1">
      <div style={{ width: "50%", backgroundColor: "#F2E3DB", margin: "auto" }}>
        <h1 className="hero-h">Promotional Banners</h1>
        <h2 className="hero-p">
          Boost your brand visibility with our high-quality, weather-resistant
          banners. A large variety of banners that will fulfil any business or
          event needs.
        </h2>
        <a href="/banners">
          <button className="hero-button">Browse Now</button>
        </a>
      </div>
      <div style={{ width: "50%", backgroundColor: "#F2E3DB" }}>
        <img
          alt="Banners"
          className="hero-image"
          src="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fhero1_1.png?alt=media&token=f0a8ab99-8fa5-4c9c-a610-1ea9f1bc428e"
          />
      </div>
    </div>
  );
};

export const hero2 = (mobile) => {
  if (mobile) {
    return (
      <div className="hero">
        <div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fstickers%2Fperforated%20sticker.png?alt=media&token=a3b87814-5792-4168-ba5f-b1dfb5f3d11e"
            alt="perforated window decals and stickers"
            className="hero-image"
          />
        </div>
        <div style={{ margin: "auto" }}>
          <h1 className="hero-h">Perforated Window Decals</h1>
          <h2 className="hero-p">
            Maximize your business's exposure without compromising on natural
            light and outdoor views.
          </h2>
          <a href="/stickers">
            <button className="hero-button">Browse Now</button>
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="hero">
      <div style={{ width: "50%" }}>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fperforated_1.jpg?alt=media&token=08c2a5ca-f9e5-405a-813b-1a7c3aea3852"
          alt="perforated window decals and stickers"
          className="hero-image"
        />
      </div>
      <div style={{ width: "50%", margin: "auto" }}>
        <h1 className="hero-h">Perforated Window Decals</h1>
        <h2 className="hero-p">
          Maximise your business's exposure without compromising on natural
          light and outdoor views.
        </h2>
        <a href="/stickers">
          <button className="hero-button">Browse Now</button>
        </a>
      </div>
    </div>
  );
};
export const hero3 = (mobile) => {
  if (mobile) {
    return (
      <div className="hero hero3" style={{ backgroundColor: "#E86A33" }}>
        <div style={{ backgroundColor: "#E86A33" }}>
          <img
            alt="feather, sail and teardrop flags"
            className="hero-image"
            src="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fhero2_1.png?alt=media&token=439376b6-5993-4eda-8b4a-7941967e31da"
          />
        </div>
        <div style={{ backgroundColor: "#E86A33", margin: "auto" }}>
          <h1 className="hero-h" style={{ color: "white" }}>
            Promotional Flags
          </h1>
          <h2 className="hero-p" style={{ color: "white" }}>
            Attract attention with our sleek promotional flags. Feather, Sail,
            Rectangular or go crazy with a teardrop design.
          </h2>
          <a href="/flags">
            <button className="hero-button green-btn">Browse Now</button>
          </a>
        </div>
      </div>
    );
  }
  return (
    <div className="hero hero3" style={{ backgroundColor: "#E86A33" }}>
      <div style={{ width: "50%", backgroundColor: "#E86A33", margin: "auto" }}>
        <h1 className="hero-h" style={{ color: "white" }}>
          Promotional Flags
        </h1>
        <h2 className="hero-p" style={{ color: "white" }}>
          Attract attention with our sleek promotional flags. Feather, Sail,
          Rectangular or go crazy with a teardrop design.
        </h2>
        <a href="/flags">
          <button className="hero-button green-btn">Browse Now</button>
        </a>
      </div>
      <div style={{ width: "50%", backgroundColor: "#E86A33" }}>
        <img
          alt="feather, sail and teardrop flags"
          className="hero-image"
          src="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fhero2_1.png?alt=media&token=439376b6-5993-4eda-8b4a-7941967e31da"
        />
      </div>
    </div>
  );
};
