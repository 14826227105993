export const data = [
  {
    name: "Banners",
    data: {
      subs: [
        {
          product: [
            {
              description:
                "Advertising Banner - Banner Printing - Outdoor Banner",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fbanner_1.jpg?alt=media&token=104e2649-933f-44b2-99e1-979cb369a380",
              name: "Vinyl Banner",
              id: "banners#vinyl",
            },
            {
              name: "Mesh Banner",
              id: "banners#mesh",
              description:
                "Outdoor Banner - Wind resistant banner - Perforated Banner",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fmesh_1.jpg?alt=media&token=df34cabb-5764-4781-aa90-067e03881bd1",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Banere%2Fbaner-textil%2FCover.jpg?alt=media&token=97f0bec6-3f32-4a54-83bd-773bf046cd22",
              name: "Textile Banner",
              description: "Indoor Banner - Building Banner - Textile Printing",
              id: "banners#textile",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fbacklit_1.jpg?alt=media&token=337be7c1-5f7d-4f2c-bab2-7a17587866a3",
              description:
                "Illuminated banner - Illuminated panel - Backlit panel",
              name: "Backlit banner",
              id: "banners#backlit",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Banere%2Fbaner-stalpi%2FCoperta.jpg?alt=media&token=0ff7bd65-5847-4b90-9738-8ea759c4f3e5",
              name: "Pole Banners",
              description: "Vertical Banner - Flag banner - Banner Printing",
              id: "banners",
            },
          ],
          name: "Printed Banners",
        },
        {
          product: [
            {
              description:
                "Standing banner • Banner with stand • Indoor advertising",
              name: "Standing X Banner",
              id: "banners#x-banner",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fxbanner_1.jpg?alt=media&token=b70d945d-c8a3-475b-9705-db51762dfe3c",
            },
            {
              description: "Custom flags - Personalised flags",
              name: "Banner Flags",
              id: "banners#flag",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fflags_1.jpg?alt=media&token=1deef5fc-d42e-4337-901e-9d9e1b6dbfc3",
            },
          ],
          name: "Standing banners",
        },
      ],
      icon: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/icons%2FBanner1.png?alt=media&token=e16137f4-ed8f-49ad-9d59-d76f9fc4daaf",
    },
    url: "banners",
  },
  {
    name: "Signs",
    data: {
      subs: [
        {
          name: "Popular signs",
          product: [
            {
              name: "Acrylic Sign",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fsign_1.jpg?alt=media&token=aeb8d5a5-68c6-46c2-b5c5-c2a0164aa19a",
              description: "Glass signs - Glass panel",
              id: "signs",
            },
            {
              name: "Plastic Sign",
              description: "Cheap signs - Plastic signs - Information plates",
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-pvc%2FCover.jpg?alt=media&token=2f69e491-a7d7-42a1-9e3d-b6363956f516",
              id: "signs",
            },
            {
              description:
                "Wooden signs - Printing on wood - Printing on plywood",
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-placaj%2FCover.jpg?alt=media&token=9fbdf57f-dea5-4c04-b090-939c75b9799f",
              name: "Wooden Sign",
              id: "signs",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-dibond%2Fmain-cover.jpg?alt=media&token=57f7660a-1bd7-414b-9ed6-27f80a53d84e",
              id: "signs",
              name: "Aluminium Sign",
              description:
                "Professional Sign - Company brand sign - Engraved sign",
            },
            {
              id: "signs",
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Semne%20Rigide%2Fsemne-policarbonat%2FCover.jpg?alt=media&token=0417cca8-31c7-497a-add5-6ed7bb2b18ca",
              description:
                "Anti-vandal sign - Polycarbonate sign - Resistant signs",
              name: "Polycarbonate Sign",
            },
          ],
        },
        {
          product: [
            {
              name: "Premium Aluminium Sign",
              description:
                "Aluminium signs - Engraved signs - Printing on Aluminium",
              id: "signs",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fsas.png?alt=media&token=a4cbcafe-14ad-447d-b5f0-2d7eb7e5ef57",
            },
            {
              id: "signs",

              description:
                "Stainless steel signs - Printing on stainless steel - Engraving on stainless steel",
              name: "Stainless Steel Sign",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fp_sign_1.jpg?alt=media&token=4ff2ce73-aac5-41c6-94bb-06101b27454b",
            },
          ],
          name: "Premium Signs",
        },
      ],
      icon: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/icons%2FMateriale%20Rigide1.png?alt=media&token=64dcb2ff-8331-4dc5-9aaf-979f7aff205d",
    },
    url: "signs",
  },
  {
    name: "Badges",
    data: {
      icon: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/icons%2FEcusoane1.png?alt=media&token=0b070ece-8a67-41e1-92d8-9f49838c9f82",
      subs: [
        {
          name: "Name Badges",
          product: [
            {
              description:
                "Economy name badges - Cheap name badge - Personalised name badge",
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-plastic%2Fcover%20ecuson%20din%20plastic.jpg?alt=media&token=b3a5a23c-9d31-44d9-8798-8b64c7e165ec",
              name: "Plastic name badge",
              id: "badges",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-aluminiu%2Faluminiu1.jpg?alt=media&token=ca230565-35ba-46b0-a1e5-a3029d0642fb",
              id: "badges",
              name: "Aluminium name badge",
              description:
                "Metal name badge - Corporate name-badge - Premium name badge",
            },
            {
              id: "badges",
              description: "Name holder - Glass name badge",
              name: "Acrylic name badge",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fbadge_1.jpg?alt=media&token=0cfa4d02-2070-4cd2-8a76-288c0651dd32",
            },
            {
              description:
                "Premium name badge - Durable and long lasting - Best name badges",
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-inox%2Finox.jpg?alt=media&token=6882162b-76a0-417f-be06-fdaf68ff3b04",
              id: "badges",
              name: "Stainless steel name badge",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-placaj%2Fcover%20ecusoane%20din%20lemn.jpg?alt=media&token=97c1b442-0a49-4de7-bce1-46ce4e647e05",
              name: "Wooden name badge",
              description:
                "Wooden badge - Eco-friendly badge - Engraved name badges",
              id: "badges",
            },
          ],
        },
        // {
        //   product: [
        //     {
        //       id: "badges",
        //       url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Produse%20Corporative%2Finsigne-metalice%2Fcover%20insigne%20metalice.jpg?alt=media&token=1aa468f5-3b04-43cd-ac92-bdf15d102e14",
        //       description: "Insigne la comndă - Insigne personalizate",
        //       name: "Metal badges",
        //     },
        //     {
        //       description: "Insigne pentru angajați - Insigne corporative ",
        //       url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Produse%20Corporative%2Finsigne-polimerice%2FUntitled-2.jpg?alt=media&token=f73eccc9-fa46-4268-99be-9c9ee0b089f7",
        //       id: "badges",
        //       name: "Plymeric badges",
        //     },
        //   ],
        //   name: "Badges",
        // },
      ],
    },
    url: "badges",
  },
  {
    name: "Stickers",
    data: {
      icon: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/icons%2FStickere1.png?alt=media&token=300da45f-4756-491e-b06c-c689a39f2763",
      subs: [
        {
          name: "Stickers & Decals",
          product: [
            {
              name: "Label Stickers",
              description: "Label sticker - Sticker for products",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Flabels_1.jpg?alt=media&token=82bb3dc3-0257-4f81-b1f5-ccdab44cd0d7",
              id: "stickers",
            },
            {
              name: "Perforated Window Sticker",
              url: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Stickere%2Fsticker-perforat%2FUntitled-2.jpg?alt=media&token=43d85110-38b0-49ca-8a10-d9065258ccea",
              id: "stickers",
              description:
                "One way vision sticker - Glass sticker - Perforated decal",
            },
            {
              name: "Window Stickers",
              description: "Window sticker - Brand Sticker",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fwindow_1.jpg?alt=media&token=6b70e999-5d18-4c19-8ef0-c0f048dc53b8",
              id: "stickers",
            },

            {
              name: "Car Stickers",
              description: "Text for car - Custom text - Car inscriptions",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fcar.png?alt=media&token=469a0876-7bf6-4331-95fc-93fe0a143e61",
              id: "stickers",
            },
            {
              name: "Wall Stickers",
              description: "Letter decals - Adhesive letters - Custom sticker",
              id: "stickers",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fwall_1.jpg?alt=media&token=d230881a-5ffa-4ae7-8207-75677b349b31",
            },
          ],
        },
      ],
    },
    url: "stickers",
  },
  {
    name: "Flags",
    data: {
      icon: "https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/icons%2FSteaguri1.png?alt=media&token=42234a71-fbdb-43a9-ba80-8f5475256277",
      subs: [
        {
          product: [
            {
              name: "Feather / Sail Flag",
              description: "Feather flag - Event flag",
              id: "flags",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fsff.png?alt=media&token=6c9351f4-6fd4-4994-a10c-f0d78ee4efe9",
            },

            {
              name: "Drop Flag",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fstf.png?alt=media&token=624a4c7f-c73e-4a40-b216-59a32cc94a6f",
              id: "flags",
              description: "Custom flags - Personalised flag - Round flag",
            },
            {
              description: "Custom flags - Personalised flags",
              name: "Banner Flags",
              id: "flags",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fsquares%2Fsbf.png?alt=media&token=f6c4ac2c-af87-4ea8-9552-19a749a86fb6",
            },
          ],
          name: "Promotional Flags",
        },
        {
          name: "National Flags",
          product: [
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Ftable-flag.jpg?alt=media&token=a113081a-0234-4613-9988-cafb913c143d",
              description: "Small flag - Table flag - Country flag",
              name: "Table Flags",
              id: "flags",
            },
            {
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fflag-great-britain.jpg?alt=media&token=7fee7c74-a766-4b35-aef4-4826dd40e49c",

              id: "flags",
              description:
                "Flag of the Republic of Moldova - Outdoor flags - EU flag",
              name: "National Flags",
            },
            {
              description:
                "Premium flag - Presidential flag - Flag of the Republic of Moldova",
              id: "flags",
              name: "Premium National Flags",
              url: "https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/optimised%2Fpflag_1.jpg?alt=media&token=b82c18a1-bcd3-45fa-b850-dd23603882f6",
            },
          ],
        },
      ],
    },
    url: "flags",
  },
];
