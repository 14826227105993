import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";

export default class PP extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          <div style={{maxWidth:'800px', margin: '20px auto'}}>
            <h1>Privacy and Cookie Policy</h1>

            <p>
              Total Promote LTD (Company Registration Number: 14818634) ("us",
              "we", or "our") operates the{" "}
              <a href="https://totalpromote.co.uk">totalpromote.co.uk</a>{" "}
              website (the "Service"). This page informs you of our policies
              regarding the collection, use, and disclosure of personal
              information when you use our Service and the choices you have
              associated with that data.
            </p>

            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy and Cookie Policy, terms used in this Privacy Policy have
              the same meanings as in our Terms and Conditions, accessible from{" "}
              <a href="https://totalpromote.co.uk">totalpromote.co.uk</a>.
            </p>

            <h2>1. Information Collection and Use</h2>

            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>

            <h3>1.1. Personal Data</h3>

            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information may include, but is not limited to:
            </p>

            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
            </ul>

            <h3>1.2. Usage Data</h3>

            <p>
              We may also collect information on how the Service is accessed and
              used ("Usage Data"). This Usage Data may include information such
              as your computer's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>

            <h2>2. Cookies and Tracking Technologies</h2>

            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
            </p>

            <p>
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Tracking technologies also
              used are beacons, tags, and scripts to collect and track
              information and to improve and analyze our Service.
            </p>

            <p>
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>

            <h2>3. Use of Data</h2>

            <p>
              Total Promote LTD uses the collected data for various purposes:
            </p>

            <ul>
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>
                To allow you to participate in interactive features of our
                Service when you choose to do so
              </li>
              <li>To provide customer support</li>
              <li>
                To gather analysis or valuable information so that we can
                improve our Service
              </li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent and address technical issues</li>
              <li>
                To provide you with news, special offers and general information
                about other goods, services, and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information
              </li>
            </ul>

            <h2>4. Legal Basis for Processing Personal Data under GDPR</h2>

            <p>
              We may process your Personal Data under the following legal bases:
            </p>

            <ul>
              <li>
                Consent: You have given your consent for processing Personal
                Data for one or more specific purposes.
              </li>
              <li>
                Performance of a contract: Provision of Personal Data is
                necessary for the performance of an agreement with you and/or
                for any pre-contractual obligations thereof.
              </li>
              <li>
                Legal obligations: Processing Personal Data is necessary for
                compliance with a legal obligation to which Total Promote LTD is
                subject.
              </li>
              <li>
                Legitimate interests: Processing Personal Data is necessary for
                the purposes of the legitimate interests pursued by Total
                Promote LTD or by a third party.
              </li>
            </ul>

            <p>
              Please note that we may process your Personal Data for more than
              one legal basis depending on the specific purpose for which we are
              using your data.
            </p>

            <h2>5. Data Retention and Transfer</h2>

            <p>
              Your information, including Personal Data, may be transferred to
              and maintained on computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>

            <p>
              Total Promote LTD will take all the steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your Personal Data will
              take place to an organization or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </p>

            <h2>6. Disclosure of Data</h2>

            <p>
              We may disclose your Personal Data in the good faith belief that
              such action is necessary to:
            </p>

            <ul>
              <li>To comply with a legal obligation</li>
              <li>
                To protect and defend the rights or property of Total Promote
                LTD
              </li>
              <li>
                To prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li>
                To protect the personal safety of users of the Service or the
                public
              </li>
              <li>To protect against legal liability</li>
            </ul>

            <h2>7. Security of Data</h2>

            <p>
              The security of your data is important to us, but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>

            <h2>8. Your Rights under GDPR</h2>

            <p>As a data subject, you have the following rights under GDPR:</p>

            <ul>
              <li>
                The right to access, update or delete the information we have on
                you
              </li>
              <li>
                The right of rectification – You have the right to have your
                information rectified if that information is inaccurate or
                incomplete
              </li>
              <li>
                The right to object – You have the right to object to our
                processing of your Personal Data
              </li>
              <li>
                The right of restriction – You have the right to request that we
                restrict the processing of your personal information
              </li>
              <li>
                The right to data portability – You have the right to be
                provided with a copy of the information we have on you in a
                structured, machine-readable and commonly used format
              </li>
              <li>
                The right to withdraw consent – You also have the right to
                withdraw your consent at any time where Total Promote LTD relied
                on your consent to process your personal information
              </li>
            </ul>

            <p>
              Please note that we may ask you to verify your identity before
              responding to such requests.
            </p>

            <p>
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>

            <h2>9. Changes to This Privacy Policy</h2>

            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>

            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy Policy.
            </p>

            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>

            <h2>10. Contact Us</h2>

            <p>
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>

            <ul>
              <li>By email: info@totalpromote.co.uk</li>
              <li>By mail: Central House, 32-66 High St. E15 2NZ, London, United Kingdom</li>
            </ul>

            <p>
              This Privacy and Cookie Policy was last updated on 09.05.2023.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
