import React, { Component } from "react";
import Footer from "../Footer";
import Header from "../Header";

export default class TC extends Component {
  render() {
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          <div style={{ maxWidth: "800px", margin: "20px auto" }}>
            <title>Terms and Conditions - Total Promote LTD</title>
            <h1>TERMS AND CONDITIONS</h1>
            <p>
              <strong>Total Promote LTD ("The Company")</strong>
            </p>

            <h2>1. GENERAL</h2>

            <p>
              1.1 These terms and conditions ("Terms") constitute the entire
              agreement between the customer ("You") and Total Promote LTD
              ("Us", "We", "Our") for the sale and purchase of our products
              ("Products"). By placing an order, you are accepting these Terms.
            </p>

            <p>
              1.2 We reserve the right to modify these Terms at any time. Any
              changes will take effect from the date they are published on our
              website.
            </p>

            <h2>2. PRODUCTS</h2>

            <p>
              2.1 We manufacture and supply a variety of promotional products
              including but not limited to banners, signs, name badges, and
              flags.
            </p>

            <p>
              2.2 All Products are subject to availability. We will inform you
              as soon as possible if a Product you have ordered is not
              available.
            </p>

            <h2>3. PRICING AND PAYMENT</h2>

            <p>
              3.1 Prices for our Products are displayed on our website and may
              change from time to time.
            </p>

            <p>
              3.2 Full payment must be received before we commence manufacturing
              your order. Exceptions can be made for larger orders, subject to
              our discretion and agreement in writing.
            </p>

            <h2>4. ORDER CANCELLATIONS AND REFUNDS</h2>

            <p>
              4.1 Orders may be cancelled and fully refunded only if
              manufacturing has not yet commenced on the Product(s).
            </p>

            <p>
              4.2 Once manufacturing has begun, we are unable to cancel your
              order or issue a refund.
            </p>

            <h2>5. RETURNS</h2>

            <p>
              5.1 We do not accept returns unless a Product is damaged or
              different from the approved design.
            </p>

            <p>
              5.2 You must notify us of any damage or discrepancy within 14 days
              of receipt. We will not be able to accept claims after this
              period.
            </p>

            <p>
              5.3 Once we have verified the claim, we will repair or replace the
              Product at our discretion.
            </p>

            <h2>6. LIABILITY</h2>

            <p>
              6.1 Our liability for losses you suffer as a result of us breaking
              this agreement is strictly limited to the purchase price of the
              Product you purchased.
            </p>

            <p>
              6.2 We are not responsible for indirect losses not reasonably
              foreseeable to both you and us when this contract was formed.
            </p>

            <h2>7. APPLICABLE LAW</h2>

            <p>
              7.1 These Terms and your use of our website are governed by
              English law. Any dispute arising from these Terms or your use of
              our website shall be resolved by the English courts.
            </p>

            <h2>8. CONTACT US</h2>

            <p>
              8.1 If you have any questions about these Terms, or wish to
              contact us for any reason, you can do so by email at{" "}
              <a href="mailto:info@totalpromote.co.uk">
                info@totalpromote.co.uk
              </a>{" "}
              or by post to Total Promote LTD, 32-66 High St, E15 2NZ, London.
            </p>

            <p>
              <strong>Date: 18 May, 2023</strong>
            </p>

            <p>
              <strong>Total Promote LTD.</strong>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
