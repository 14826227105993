/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Dropdown, Drawer, Menu } from "antd";
import "./Header.css";
import logo from "../img/logo.png";

import { MenuOutlined, UpOutlined, MailOutlined } from "@ant-design/icons";



export default class Header extends Component {
  state = {
    servicii: {},
    categories: [],
    hovered: {},
    announcements: null,
    visible: false,
    contact: false,
  };

  componentDidMount = () => {
    const scrollFunction = () => {
      var upBtn = document.getElementById("upBtn");
      if (
        document.body.scrollTop > 200 ||
        document.documentElement.scrollTop > 200
      ) {
        upBtn.style.display = "block";
      } else {
        upBtn.style.display = "none";
      }
    };
    window.onscroll = function () {
      scrollFunction();
    };
    this.setState({
      categories: this.props.categories,
    });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  getMenu = (id) => {
    var categories = this.state.categories;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].name === id) {
        const list = categories[i].data.subs.map((data) => (
          <div key={data.name}>
            <div>
              <h4>{data.name}</h4>
              {data.product.map((product) => (
                <li key={product.id}>
                  <a
                    onMouseEnter={() => this.onLinkHover(product)}
                    href={"/" + product.id}
                    className="product-link"
                  >
                    {product.name}
                  </a>
                </li>
              ))}
              <br />
            </div>
          </div>
        ));
        return (
          <div className="dropdown">
            <div className="dropdown-menu">{list}</div>
            <div className="dropdown-image-container">
              <img
                alt={this.state.hovered.name}
                className="dropdown-image"
                src={this.state.hovered.url}
              />
              <title style={{ textAlign: "center", fontSize: "18px", display:'block'}}>
                {this.state.hovered.name}
              </title>
            </div>
          </div>
        );
      }
    }
  };

  onLinkHover = (product) => {
    this.setState({
      hovered: product,
    });
  };
  showDrawer = () => {
    this.setState({ visible: true });
  };
  onClose = () => {
    this.setState({ visible: false });
  };

  showContact = () => {
    this.setState({ contact: true });
  };

  closeContact = () => {
    this.setState({ contact: false });
  };

  topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getPadding(str) {
    if (str.length > 77) {
      return "20px 60px";
    }
    return "30px 60px";
  }

  renderLogo = () => {
    return (
      <a href="/" style={{ display: "flex" }}>
        <img
          className="logo"
          alt="Total Promote - Banners, signs, stickers, flags, etc."
          src={logo}
        ></img>
      </a>
    );
  };

  render() {
    const categories = this.state.categories;
    if (this.state.mobile) {
      return (
        <header>
          <div id="header-top">
            {this.renderLogo()}
            <MenuOutlined
              onClick={() => this.showDrawer()}
              style={{
                fontSize: "24px",
                color: "#000",
                lineHeight: "56px",
                marginTop: "2px",
              }}
            />
            <Drawer
              title="Menu"
              placement="right"
              closable={true}
              onClose={() => this.onClose()}
              open={this.state.visible}
              width={280}
              bodyStyle={{ overflowX: "hidden" }}
            >
              <Menu mode="inline" style={{ width: 280, overflowX: "hidden" }}>
                {categories.map((cat) => (
                  <Menu.Item
                    icon={
                      <img
                        style={{
                          height: "20px",
                          margin: "auto 16px auto 0px",
                        }}
                        alt="Total promote"
                        src={cat.data.icon}
                      />
                    }
                    key={cat.name}
                    title={cat.name}
                    onClick
                  >
                    <a href={"/" + cat.url}  className="mobile-link">
                      {cat.name}
                    </a>
                  </Menu.Item>
                ))}
                <Menu.Item
                key='contact'
                  icon={
                    <div
                      style={{
                        height: "20px",
                        margin: "auto 16px auto 4px",
                      }}
                    >
                      <MailOutlined />
                    </div>
                  }
                  title={"Contact us"}
                  onClick
                >
                  <a href={"/contact"} className="mobile-link">
                    Contact
                  </a>
                </Menu.Item>
              </Menu>
            </Drawer>
          </div>
          <div id="header-bar"></div>
          <UpOutlined
            className="up-button"
            onClick={() => this.topFunction()}
            id="upBtn"
          />
        </header>
      );
    }
    return (
      <header>
        <div id="header-top">
          {this.renderLogo()}
          <div id="header-menu">
            {categories.map((cat) => (
              <Dropdown
                key={cat.name}
                overlay={this.getMenu(cat.name)}
                onVisibleChange={() =>
                  this.onLinkHover(cat.data.subs[0].product[0])
                }
              >
                <span>
                  <a className="ant-dropdown-link" href={"/" + cat.url}>
                    {cat.name}
                  </a>{" "}
                  •
                </span>
              </Dropdown>
            ))}
            <a className="ant-dropdown-link contact" href={"/contact"}>
              Contact
            </a>
          </div>
        </div>
        <div id="header-bar"></div>
        <UpOutlined
          className="up-button"
          onClick={() => this.topFunction()}
          id="upBtn"
        />
      </header>
    );
  }
}
