import { Col, Row } from "antd";
import React, { Component } from "react";
import "./Footer.css";

import insta from "..//img/insta.png";
import linkedin from "..//img/linkedin.png";

export default class Footer extends Component {
  state = {
    mobile: "",
  };

  componentDidMount = () => {
    // openKey.addEventListener('keys', this.setState({openKeys: openKey}))
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  };

  resize = () => {
    let currentWidth = window.innerWidth <= 1024;
    if (currentWidth !== this.state.mobile) {
      this.setState({ mobile: currentWidth });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  socialIcons = () => {
    return (
      <div style={{ textAlign: "center", margin: "auto" }}>
        <a
          className="futerLink"
          title="Instagram"
          href="https://www.instagram.com/totalpromoteltd/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="futerImg" alt="instagram total promote" src={insta} />
        </a>
        <a
          className="futerLink"
          href="https://www.linkedin.com/company/totalpromote/"
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <img className="futerImg" alt="total promote linkedin" src={linkedin} />
        </a>
      </div>
    );
  };

  usefulLinksJsx = () => {
    return (
      <>
        <h3>Useful links:</h3>
        <a href="/contact" className="footer-link">
          Contact
        </a>
        <a href="/about" className="footer-link">
          About us
        </a>
        <a href="/technical" className="footer-link">
          Tech & Capabilities
        </a>
        <a href="/privacy-policy" className="footer-link">
          Privacy Policy
        </a>
        <a href="/terms" className="footer-link">
          Terms & Conditions
        </a>
      </>
    );
  };

  shopLinksJsx = () => {
    return (
      <>
        <h3>Shop now:</h3>
        <a href="/banners" className="footer-link">
          Banners
        </a>
        <a href="/signs" className="footer-link">
          Signs
        </a>
        <a href="/badges" className="footer-link">
          Name Badges
        </a>
        <a href="/stickers" className="footer-link">
          Stickers & Decals
        </a>
        <a href="/flags" className="footer-link">
          Flags
        </a>
      </>
    );
  };

  contactsJsx = () => {
    return (
      <>
        {this.socialIcons()}
        <br />
        <b>⊜ Total Promote LTD</b>
        <div style={{ height: "8px" }}></div>
        <a href="mailto:info@totalpromote.co.uk" className="footer-link">
          info@totalpromote.co.uk
        </a>
      </>
    );
  };

  render() {
    if (this.state.mobile) {
      return (
        <div>
          <div style={{ textAlign: "center" }} className="footer-cont">
            <Col span="24">
              {this.contactsJsx()}
              {this.usefulLinksJsx()}
              <p>&copy; {new Date().getFullYear()}</p>
            </Col>
          </div>
        </div>
      );
    }

    return (
      <div style={{ textAlign: "center" }} className="footer-cont">
        <Row style={{ maxWidth: "1360px", margin: "auto" }}>
          <Col span="8">{this.shopLinksJsx()}</Col>
          <Col span="8">{this.contactsJsx()}</Col>
          <Col span="8">{this.usefulLinksJsx()}</Col>
        </Row>
        <p>&copy; {new Date().getFullYear()}</p>
      </div>
    );
  }
}
