import React, { Component } from "react";
import Header from "../Header";
import Footer from "../Footer";
import ProductPresent from "../blocks/ProductPresent/ProductPresent";
import Specs from "../blocks/Specs/Specs";
import { Divider } from "antd";
import { logEvent } from "firebase/analytics";

export default class Badges extends Component {

  componentDidMount() {
    logEvent(this.props.anal, "badges_visited");
  }
  render() {
    
    return (
      <div>
        <Header db={this.props.db} categories={this.props.categories} />
        <div className="tpc">
          <h1 className="sub-h">Standard Name Badges</h1>
          <ProductPresent
            text="Perfect for conferences, corporate events, and daily workplace interactions, these badges foster a sense of unity and promote networking. Featuring durable materials and crisp, clear printing, our standard name badges help your team stand out while ensuring easy identification. Invest in these personalised badges to elevate your brand's presence and create a polished, cohesive appearance."
            title="Name Badges"
            img="https://firebasestorage.googleapis.com/v0/b/total-promote.appspot.com/o/webcontent%2Fbadges%2Fbadges.png?alt=media&token=bd30c1cd-df4c-49f9-898a-ee94bd8bcc72"
            alt="Standard name Badges"
            link="contact"
            price="3.80"
            reverse={false}
          />
          <ProductPresent
            text="Embrace flexibility and sustainability with our innovative reusable name badges. Ideal for businesses with frequent staff changes, events with rotating attendees, or organizations seeking an eco-friendly solution, these badges offer an adaptable, cost-effective alternative. Easily update names and titles with our user-friendly design, while maintaining a professional, consistent appearance. Choose our reusable name badges to support your ever-evolving needs and promote a greener, more efficient business approach."
            title="Reusable Badges"
            img="https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-plastic%2Fgalerie%2Fradison%201200x800.jpg?alt=media&token=bcf0614f-5a75-4ed8-bfa8-f34515d24e18"
            alt="reusable name Badges"
            link="contact"
            price="4.50"
            reverse={true}
          />
          <div className="inline-spec">
            <Specs
              list={[
                {
                  name: "Materials",
                  description: "Plastic (ABS), Acrylic, Wooden",
                },
                {
                  name: "Print",
                  description: "Sublimation/UV Print or Engraved",
                },
                { name: "Coating", description: "No" },
                {
                  name: "Fitting",
                  description: "Clip (Magnet available for £0.99)",
                },
              ]}
            />
            <Specs
              h="Reusable:"
              list={[
                {
                  name: "1-24 units",
                  description: "£8.60",
                },
                {
                  name: "25+ units",
                  description: "£5.90",
                },
                { name: "100+ units", description: "£4.50" },
              ]}
              price={true}
            />
            <Specs
              list={[
                {
                  name: "1-24 units",
                  description: "£7.30",
                },
                {
                  name: "25+ units",
                  description: "£5.20",
                },
                { name: "100+ units", description: "£3.80" },
              ]}
              price={true}
              h="Standard price:"
            />
          </div>
          <Divider style={{ marginTop: "0px" }} />
          {/* PREMIUM */}

          <h1 className="sub-h">Premium Name Badges</h1>
          <ProductPresent
            text="Make a lasting impression with our luxurious, custom premium Aluminium name badges. Crafted from high-quality Aluminium, these badges offer an elegant, refined look for conferences, corporate events, and daily workplace interactions. The lightweight yet durable design ensures longevity and comfort. Our premium badges come with a magnetic fastening system, providing a secure and damage-free attachment. Elevate your brand image and showcase your team's professionalism with our stunning Aluminium name badges."
            title="Premium Name Badges"
            img="https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-aluminiu%2Fce_e_ecuson_aluminiu.jpg?alt=media&token=d11d95f2-ddf1-42ab-95ed-df8cbe9fbf74"
            alt="Premium Name Badges"
            link="contact"
            price="6.20"
            reverse={false}
          />
          <ProductPresent
            text="Exude sophistication and style while embracing flexibility and sustainability with our top-of-the-line, reusable name badges. These premium badges are made from durable, corrosion-resistant materials, providing a sleek, modern appearance for any professional setting. Ideal for businesses with frequent staff changes or eco-conscious organizations, these adaptable badges offer a cost-effective solution. The high-quality printing ensures crisp, clear information display, while the magnetic fastening system eliminates the need for pins or clips. Invest in these upscale, reusable name badges to enhance your brand's prestige, support your ever-evolving needs, and promote a polished, unified look with a greener approach."
            title="Premium Reusable Badges"
            img="https://firebasestorage.googleapis.com/v0/b/total-gravura.appspot.com/o/Ecusoane%2Fecusoane-aluminiu%2Fgalerie%2Fnew-york%201200x800.jpg?alt=media&token=33ee49c8-95d7-4db2-8da4-a4967ecdeac1"
            alt="Premium Reusable Badges"
            link="contact"
            price="6.90"
            reverse={true}
          />
          <div className="inline-spec">
            <Specs
              list={[
                {
                  name: "Materials",
                  description: "Aluminium, Plastic (ABS), Acrylic, Wooden",
                },
                {
                  name: "Print",
                  description: "Sublimation/UV Print",
                },
                { name: "Coating", description: "Polymer gel" },
                {
                  name: "Fitting",
                  description: "Magnet",
                },
              ]}
            />
            <Specs
              h="Premium reusable:"
              list={[
                {
                  name: "1-24 units",
                  description: "£11.10",
                },
                {
                  name: "25+ units",
                  description: "£8.30",
                },
                { name: "100+ units", description: "£6.90" },
              ]}
              price={true}
            />
            <Specs
              list={[
                {
                  name: "1-24 units",
                  description: "£10.40",
                },
                {
                  name: "25+ units",
                  description: "£7.90",
                },
                { name: "100+ units", description: "£6.20" },
              ]}
              price={true}
              h="Premium badge:"
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
